import { compareDesc } from 'date-fns';

import { PRODUCT_TYPES } from 'app/constants/app';
import {
  CONTROL_CHECKS_IS_COMPLIANT,
  CONTROL_SORT_OPTIONS,
  CONTROL_STATUS_VALUES
} from 'app/constants/controls';
import { DASHBOARD_STATUS_VALUES } from 'app/constants/dashboard';
import { FILTER_PROP_NAMES, YES_NO_VALUE } from 'app/constants/filters';
import {
  AzureCustomerControl,
  AzureDashboardControl,
  CommonFilters,
  CustomerControlCheck,
  M365CustomerControl,
  M365DashboardControl
} from 'app/types';

/**
 * URLSearchParams
 */

export const updateSearchParamsWithFilter = (
  urlParams = new URLSearchParams(),
  prop: FILTER_PROP_NAMES,
  value?: string | string[]
) => {
  // remove the old value from params
  urlParams.delete(prop);

  // add the new value (if we have one)
  if (value) {
    if (Array.isArray(value)) {
      value.forEach(val => urlParams.append(prop, val));
    } else {
      urlParams.append(prop, value);
    }
  }

  // return the updated params
  return urlParams;
};

export const buildSearchParamsFromFilters = (
  filters: CommonFilters,
  urlParams = new URLSearchParams()
) => {
  urlParams.delete(FILTER_PROP_NAMES.FREETEXT);
  if (filters.freeText) urlParams.append(FILTER_PROP_NAMES.FREETEXT, filters.freeText);

  urlParams.delete(FILTER_PROP_NAMES.IMPACT);
  if (filters.impact)
    filters.impact.forEach(val => urlParams.append(FILTER_PROP_NAMES.IMPACT, val));

  urlParams.delete(FILTER_PROP_NAMES.INITIAL_FINDINGS);
  if (filters.initialFindings)
    filters.initialFindings.forEach(val =>
      urlParams.append(FILTER_PROP_NAMES.INITIAL_FINDINGS, val)
    );

  urlParams.delete(FILTER_PROP_NAMES.LICENSED);
  if (filters.licensed)
    filters.licensed.forEach(val => urlParams.append(FILTER_PROP_NAMES.LICENSED, val));

  urlParams.delete(FILTER_PROP_NAMES.RISK);
  if (filters.risk) filters.risk.forEach(val => urlParams.append(FILTER_PROP_NAMES.RISK, val));

  urlParams.delete(FILTER_PROP_NAMES.SOLUTIONS);
  if (filters.solutionIds)
    filters.solutionIds.forEach(val => urlParams.append(FILTER_PROP_NAMES.SOLUTIONS, val));

  urlParams.delete(FILTER_PROP_NAMES.NIS_PRINCIPLES);
  if (filters.nisPrinciplesIds)
    filters.nisPrinciplesIds.forEach(val =>
      urlParams.append(FILTER_PROP_NAMES.NIS_PRINCIPLES, val)
    );

  urlParams.delete(FILTER_PROP_NAMES.STATUSES);
  if (filters.statuses)
    filters.statuses.forEach(val => urlParams.append(FILTER_PROP_NAMES.STATUSES, val));

  urlParams.delete(FILTER_PROP_NAMES.CONTROL_TYPES);
  if (filters.controlTypes)
    filters.controlTypes.forEach(val => urlParams.append(FILTER_PROP_NAMES.CONTROL_TYPES, val));

  urlParams.delete(FILTER_PROP_NAMES.BENCHMARKS);
  if (filters.benchmarkIds)
    filters.benchmarkIds.forEach(val => urlParams.append(FILTER_PROP_NAMES.BENCHMARKS, val));

  return urlParams;
};

export const buildFiltersFromSearchParams = (
  urlParams: URLSearchParams,
  dashboardStatuses = false
): CommonFilters => {
  const impact = urlParams.getAll(FILTER_PROP_NAMES.IMPACT);
  const initialFindings = urlParams.getAll(FILTER_PROP_NAMES.INITIAL_FINDINGS);
  const licensed = urlParams.getAll(FILTER_PROP_NAMES.LICENSED);
  const nisPrinciplesIds = urlParams.getAll(FILTER_PROP_NAMES.NIS_PRINCIPLES);
  const risk = urlParams.getAll(FILTER_PROP_NAMES.RISK);
  const solutionIds = urlParams.getAll(FILTER_PROP_NAMES.SOLUTIONS);
  const controlTypes = urlParams.getAll(FILTER_PROP_NAMES.CONTROL_TYPES);
  const benchmarkIds = urlParams.getAll(FILTER_PROP_NAMES.BENCHMARKS);

  const statusFromParams = urlParams.getAll(FILTER_PROP_NAMES.STATUSES);
  const statusValues = dashboardStatuses ? DASHBOARD_STATUS_VALUES : CONTROL_STATUS_VALUES;

  const statuses = statusFromParams
    .map(paramStatus => (statusValues as string[]).find(val => val === paramStatus))
    .filter(val => !!val) as string[];

  return {
    freeText: urlParams.get(FILTER_PROP_NAMES.FREETEXT) || undefined,
    impact: impact.length ? impact : undefined,
    initialFindings: initialFindings.length ? initialFindings : undefined,
    licensed: licensed.length ? licensed : undefined,
    nisPrinciplesIds: nisPrinciplesIds.length ? nisPrinciplesIds : undefined,
    risk: risk.length ? risk : undefined,
    solutionIds: solutionIds.length ? solutionIds : undefined,
    statuses: statuses.length ? statuses : undefined,
    controlTypes: controlTypes.length ? controlTypes : undefined,
    benchmarkIds: benchmarkIds.length ? benchmarkIds : undefined
  };
};

/**
 * Filtering
 */

const FILTER_FUNCS = {
  freeText: (
    control: M365CustomerControl | M365DashboardControl | AzureCustomerControl,
    { freeText }: CommonFilters
  ) =>
    control.externalControlId.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()) ||
    control.name.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()) ||
    control.controlNotes?.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()) ||
    control.controlPosture?.content.toLocaleLowerCase().includes(freeText!.toLocaleLowerCase()),
  initialFindings: (
    control: M365CustomerControl | M365DashboardControl | AzureCustomerControl,
    { initialFindings }: CommonFilters
  ) =>
    (initialFindings || []).includes(control.controlPosture ? YES_NO_VALUE.YES : YES_NO_VALUE.NO),
  impact: (
    control: M365CustomerControl | M365DashboardControl | AzureCustomerControl,
    { impact }: CommonFilters
  ) => (impact || []).includes(control.businessRisk?.toLocaleUpperCase() ?? ''),
  risk: (
    control: M365CustomerControl | M365DashboardControl | AzureCustomerControl,
    { risk }: CommonFilters
  ) => (risk || []).includes(control.securityRisk?.toLocaleUpperCase() ?? ''),
  statuses: (
    control: M365CustomerControl | M365DashboardControl | AzureCustomerControl,
    { statuses }: CommonFilters
  ) => (statuses || []).includes(control.status ?? ''),
  solutionIds: (
    control: M365CustomerControl | M365DashboardControl | AzureCustomerControl,
    { solutionIds }: CommonFilters
  ) => (solutionIds || []).includes(`${control.solution?.id}`)
};

const FILTER_M365_FUNCS = {
  ...FILTER_FUNCS,
  licensed: (control: M365CustomerControl | M365DashboardControl, { licensed }: CommonFilters) =>
    (licensed || []).includes(control.licenced ? YES_NO_VALUE.YES : YES_NO_VALUE.NO),
  nisPrinciplesIds: (
    control: M365CustomerControl | M365DashboardControl,
    { nisPrinciplesIds }: CommonFilters
  ) => (control.nisPrinciples || []).some(v => (nisPrinciplesIds || []).includes(`${v.id}`))
};

const FILTER_AZURE_FUNCS = {
  ...FILTER_FUNCS,
  controlTypes: (
    control: AzureCustomerControl | AzureDashboardControl,
    { controlTypes }: CommonFilters
  ) => (controlTypes || []).includes(control.type?.toLocaleUpperCase() ?? ''),
  benchmarkIds: (
    control: AzureCustomerControl | AzureDashboardControl,
    { benchmarkIds }: CommonFilters
  ) => (control.benchmarks || []).some(v => (benchmarkIds || []).includes(`${v.id}`))
};

const checkM365ControlMatchesFilters = (
  control: M365CustomerControl | M365DashboardControl,
  prop: keyof CommonFilters,
  filters: CommonFilters
) =>
  filters[prop]
    ? !!FILTER_M365_FUNCS[prop as keyof typeof FILTER_M365_FUNCS](control, filters)
    : true;

const checkAzureControlMatchesFilters = (
  control: AzureCustomerControl | AzureDashboardControl,
  prop: keyof CommonFilters,
  filters: CommonFilters
) =>
  filters[prop]
    ? !!FILTER_AZURE_FUNCS[prop as keyof typeof FILTER_AZURE_FUNCS](control, filters)
    : true;

export const filterM365Controls = (controls: M365CustomerControl[], filters: CommonFilters) => {
  if (controls.length === 0) return controls;

  const filteredData = controls.filter(
    control =>
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.FREETEXT, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.IMPACT, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.INITIAL_FINDINGS, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.LICENSED, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.NIS_PRINCIPLES, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.RISK, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.SOLUTIONS, filters) &&
      checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.STATUSES, filters)
  );

  return filteredData;
};

export const filterAzureControls = (controls: AzureCustomerControl[], filters: CommonFilters) => {
  if (controls.length === 0) return controls;

  const filteredData = controls.filter(
    control =>
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.FREETEXT, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.IMPACT, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.INITIAL_FINDINGS, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.RISK, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.SOLUTIONS, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.STATUSES, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.CONTROL_TYPES, filters) &&
      checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.BENCHMARKS, filters)
  );

  return filteredData;
};

export const filterDashboardControls = (
  controls: Array<M365DashboardControl | AzureDashboardControl>,
  filters: CommonFilters,
  type: PRODUCT_TYPES
) => {
  if (controls.length === 0) return controls;
  if (type === PRODUCT_TYPES.M365) {
    const m365FilteredData = (controls as Array<M365DashboardControl>).filter(
      control =>
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.IMPACT, filters) &&
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.INITIAL_FINDINGS, filters) &&
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.LICENSED, filters) &&
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.NIS_PRINCIPLES, filters) &&
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.RISK, filters) &&
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.SOLUTIONS, filters) &&
        checkM365ControlMatchesFilters(control, FILTER_PROP_NAMES.STATUSES, filters)
    );

    return m365FilteredData;
  } else if (type === PRODUCT_TYPES.AZURE) {
    const azureFilteredData = (controls as Array<AzureDashboardControl>).filter(
      control =>
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.IMPACT, filters) &&
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.INITIAL_FINDINGS, filters) &&
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.RISK, filters) &&
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.SOLUTIONS, filters) &&
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.STATUSES, filters) &&
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.CONTROL_TYPES, filters) &&
        checkAzureControlMatchesFilters(control, FILTER_PROP_NAMES.BENCHMARKS, filters)
    );

    return azureFilteredData;
  }
};

/**
 * Sorting
 */
const convertRiskToNumber = (risk?: string) => {
  if (risk) {
    if (risk.toLocaleLowerCase() === 'high') return 0;
    if (risk.toLocaleLowerCase() === 'medium') return 1;
    if (risk.toLocaleLowerCase() === 'low') return 2;
  }
  return 3;
};

export const sortM365Controls = (controls: Array<M365CustomerControl>, sort: string) => {
  const [sortProperty, sortDir] = sort.split(',');

  if (sortProperty === 'securityRisk' || sortProperty === 'businessRisk') {
    return sortDir === 'asc'
      ? controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) < convertRiskToNumber(b[sortProperty]) ? -1 : 1
        )
      : controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) > convertRiskToNumber(b[sortProperty]) ? -1 : 1
        );
  }

  if (sortProperty === 'lastUpdatedAt') {
    return controls.sort((a, b) => {
      if (!a.lastUpdatedAt) return 1;
      if (!b.lastUpdatedAt) return -1;

      return compareDesc(new Date(a.lastUpdatedAt), new Date(b.lastUpdatedAt));
    });
  }

  // default is by externalControlId
  return controls.sort((a, b) => (a.externalControlId < b.externalControlId ? -1 : 1));
};

export const sortAzureControls = (controls: AzureCustomerControl[], sort: string) => {
  const [sortProperty, sortDir] = sort.split(',');

  if (sortProperty === 'securityRisk' || sortProperty === 'businessRisk') {
    return sortDir === 'asc'
      ? controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) < convertRiskToNumber(b[sortProperty]) ? -1 : 1
        )
      : controls.sort((a, b) =>
          convertRiskToNumber(a[sortProperty]) > convertRiskToNumber(b[sortProperty]) ? -1 : 1
        );
  }

  if (sortProperty === 'lastUpdatedAt') {
    return controls.sort((a, b) => {
      if (!a.lastUpdatedAt) return 1;
      if (!b.lastUpdatedAt) return -1;

      return compareDesc(new Date(a.lastUpdatedAt), new Date(b.lastUpdatedAt));
    });
  }

  // default is by externalControlId
  return controls.sort((a, b) => (a.externalControlId < b.externalControlId ? -1 : 1));
};

export const createFiltersLink = (filters: CommonFilters, sort: string) =>
  new URLSearchParams(
    Object.entries(filters)
      .flatMap(([key, value]) =>
        value !== undefined
          ? Array.isArray(value)
            ? value.map(v => [key, v])
            : [[key, value]]
          : []
      )
      .concat(sort == CONTROL_SORT_OPTIONS[0].value ? [] : [['sort', sort]])
  ).toString();

export const controlCheckCheckDataObject = (controlCheck: CustomerControlCheck) =>
  controlCheck.checkData!.reduce((propertyValues, item) => {
    propertyValues[item.name as string] = item.value;
    return propertyValues;
  });

export const getOverallComplianceLabel = (overallComplianceStatus: CONTROL_CHECKS_IS_COMPLIANT) => {
  switch (overallComplianceStatus) {
    case CONTROL_CHECKS_IS_COMPLIANT.COMPLIANT:
      return 'Compliant';
    case CONTROL_CHECKS_IS_COMPLIANT.NOT_COMPLIANT:
      return 'Not compliant';
    case CONTROL_CHECKS_IS_COMPLIANT.NOT_APPLICABLE:
    default:
      return 'Review';
  }
};
