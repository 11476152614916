import { format, isThisMonth, startOfDay, subMonths } from 'date-fns';

import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { AzureDashboardControl, M365DashboardControl, RangeType, Solution } from 'app/types';

type SolutionControlGroup = {
  solution: Solution;
  controls: Array<M365DashboardControl | AzureDashboardControl>;
};

export const sortByStatus = (
  a: M365DashboardControl | AzureDashboardControl,
  b: M365DashboardControl | AzureDashboardControl
) =>
  Object.values(DASHBOARD_STATUS).indexOf(a.status) -
  Object.values(DASHBOARD_STATUS).indexOf(b.status);

export const groupBySolution = (
  dashboardControls: Array<M365DashboardControl | AzureDashboardControl>
): SolutionControlGroup[] => {
  const solutionGroups: SolutionControlGroup[] = [];

  const uniqueSolutions = [...new Set(dashboardControls.map(val => val.solution?.id))];

  uniqueSolutions.forEach(solutionId => {
    const controlsForSolution = dashboardControls.filter(
      ({ solution }) => solution?.id === solutionId
    );

    const statusesCompletedThisMonth = controlsForSolution.filter(
      ({ status, completedAt }) =>
        status === DASHBOARD_STATUS.COMPLETED && completedAt && isThisMonth(completedAt)
    );

    solutionGroups.push({
      solution: dashboardControls.find(({ solution }) => solution?.id === solutionId)!.solution!,
      controls: [
        ...controlsForSolution.filter(status => !statusesCompletedThisMonth.includes(status)),
        // change status name from COMPLETED to COMPLETED_THIS_MONTH when completedAt is this month
        ...statusesCompletedThisMonth.map(status => ({
          ...status,
          status: DASHBOARD_STATUS.COMPLETED_THIS_MONTH
        }))
      ].sort(sortByStatus)
    });
  });
  return solutionGroups;
};

export const isCompletedThisMonth = (control: M365DashboardControl | AzureDashboardControl) =>
  control.completedAt && isThisMonth(control.completedAt)
    ? DASHBOARD_STATUS.COMPLETED_THIS_MONTH
    : control.status;

export const tooltipDateFormatter = (date: Date) => {
  return format(new Date(date), 'MMM dd');
};

export const groupedAndSortedControls = (
  completedControls: Array<M365DashboardControl | AzureDashboardControl>
) =>
  Object.entries(
    completedControls.reduce(
      (acc: { [key: string]: Array<M365DashboardControl | AzureDashboardControl> }, curr) => {
        if (curr.completedAt) {
          acc[curr.completedAt] = acc[curr.completedAt] || [];
          acc[curr.completedAt].push(curr);
        }
        return acc;
      },
      {}
    )
  ).sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime());

export const getRangeStartDate = (selectedRange: RangeType) =>
  subMonths(startOfDay(new Date()), { '1m': 1, '3m': 3, '6m': 6, '12m': 12 }[selectedRange] || 1);
