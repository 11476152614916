import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { WHATS_NEW_API_URLS } from 'app/constants/routes';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  PagedResponse,
  UpdateWhatsNewBulletinMutationOptions,
  WhatsNew,
  WhatsNewBulletin,
  WrappedError
} from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

export const USE_FETCH_WHATS_NEW_QUERY_KEY = 'useFetchWhatsNew';
export const useFetchWhatsNewList = (enabled = false) => {
  const { selectedCustomer } = useSelectedCustomer();
  const options = {
    customerId: selectedCustomer?.id,
    size: 20,
    sort: 'createdAt,desc'
  };

  return useInfiniteQuery<PagedResponse<WhatsNew>, WrappedError>({
    queryKey: [USE_FETCH_WHATS_NEW_QUERY_KEY, options],
    queryFn: async ({ pageParam }) =>
      await axiosInstance
        .get<PagedResponse<WhatsNew>>(WHATS_NEW_API_URLS.ROOT, {
          params: { ...options, page: pageParam }
        })
        .then(response => response.data)
        .catch(catchApiError),
    enabled,
    gcTime: 1000,
    initialPageParam: 0,
    getNextPageParam: ({ page }) => (page.totalPages > page.number + 1 ? page.number + 1 : null)
  });
};

export interface CreateWhatsNewBulletinOptions {
  content: string;
  externalControlId: string;
}

export const useCreateWhatsNewBulletinMutation = () => {
  const queryClient = useQueryClient();
  return useMutation<WhatsNewBulletin, WrappedError, CreateWhatsNewBulletinOptions>({
    mutationKey: ['useCreateMasterControlMutation'],
    mutationFn: async ({ content, externalControlId }: CreateWhatsNewBulletinOptions) =>
      await axiosInstance
        .post<WhatsNewBulletin>(WHATS_NEW_API_URLS.CREATE, {
          content: content.trim(),
          externalControlId: externalControlId
        })
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USE_FETCH_WHATS_NEW_QUERY_KEY] })
  });
};

export const useUpdateWhatsNewBulletinMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<WhatsNewBulletin, WrappedError, UpdateWhatsNewBulletinMutationOptions>({
    mutationKey: ['useUpdateWhatsNewBulletinMutation'],
    mutationFn: async ({ id, content }: UpdateWhatsNewBulletinMutationOptions) =>
      await axiosInstance
        .put<WhatsNewBulletin>(WHATS_NEW_API_URLS.SINGLE.replace(':id', `${id}`), {
          content
        })
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_WHATS_NEW_QUERY_KEY] });
    }
  });
};

export const useDeleteWhatsNewBulletinMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useDeleteWhatsNewBulletinMutation'],
    mutationFn: async (id: number) =>
      await axiosInstance
        .delete(WHATS_NEW_API_URLS.SINGLE.replace(':id', `${id}`))
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_WHATS_NEW_QUERY_KEY] });
    }
  });
};
