import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import {
  AZURE_DASHBOARD_CONTROLS_API_ROOT,
  M365_DASHBOARD_CONTROLS_API_ROOT
} from 'app/constants/routes';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import { AzureDashboardControl, M365DashboardControl, WrappedError } from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

export const USE_FETCH_M365_DASHBOARD_CONTROLS_QUERY_KEY = 'm365-dashboard-controls';
export const useFetchM365DashboardControls = (enabled = false) => {
  const { selectedCustomer } = useSelectedCustomer();

  return useQuery<M365DashboardControl[], WrappedError>({
    queryKey: [USE_FETCH_M365_DASHBOARD_CONTROLS_QUERY_KEY],
    queryFn: async () =>
      await axiosInstance
        .get<M365DashboardControl[]>(M365_DASHBOARD_CONTROLS_API_ROOT.ROOT, {
          params: { customerId: selectedCustomer?.id }
        })
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
};

export const USE_FETCH_AZURE_DASHBOARD_CONTROLS_QUERY_KEY = 'azure-dashboard-controls';
export const useFetchAzureDashboardControls = (enabled = false) => {
  const { selectedCustomer } = useSelectedCustomer();

  return useQuery<AzureDashboardControl[], WrappedError>({
    queryKey: [USE_FETCH_AZURE_DASHBOARD_CONTROLS_QUERY_KEY],
    queryFn: async () =>
      await axiosInstance
        .get<AzureDashboardControl[]>(AZURE_DASHBOARD_CONTROLS_API_ROOT.ROOT, {
          params: { customerId: selectedCustomer?.id }
        })
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
};
