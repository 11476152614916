import { FunctionComponent, useState } from 'react';
import { Button, Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { MAX_NUMBER_OF_SHOWED_CONTROL_CHECKS_ARRAY_ITEMS } from 'app/constants/controls';

const useStyles = tss.withName('ControlCheckItemArray').create(() => ({
  formItemValue: {
    fontSize: '12px',
    lineHeight: 1.67,
    color: 'var(--font-secondary-color)'
  },
  button: {
    textDecoration: 'underline',
    color: 'var(--font-link-icon-color)',
    width: 'fit-content',
    cursor: 'pointer'
  }
}));

type ControlCheckItemArrayProps = {
  value: string[] | number[];
  displayItems?: number;
};

export const ControlCheckItemArray: FunctionComponent<ControlCheckItemArrayProps> = ({
  value,
  displayItems
}) => {
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <Flex direction='column' data-testid='control-check-item-array'>
      {value
        .map((item, index) => (
          <Text key={index} className={classes.formItemValue}>
            {item}
            {index !== value.length - 1 && ','}
          </Text>
        ))
        .slice(
          0,
          open ? value.length : (displayItems ?? MAX_NUMBER_OF_SHOWED_CONTROL_CHECKS_ARRAY_ITEMS)
        )}
      {value.length > (displayItems ?? MAX_NUMBER_OF_SHOWED_CONTROL_CHECKS_ARRAY_ITEMS) && (
        <Button
          onClick={() => setOpen(!open)}
          variant='ghost'
          className={classes.button}
          data-testid='control-check-item-array-expand-button'
          size='1'
          color='gray'
        >
          {open
            ? 'Show less'
            : `Show all (${value.length - (displayItems ?? MAX_NUMBER_OF_SHOWED_CONTROL_CHECKS_ARRAY_ITEMS)} more)`}
        </Button>
      )}
    </Flex>
  );
};
