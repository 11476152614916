import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { PRODUCT_TYPES } from 'app/constants/app';
import { concatUserTypeAndRole } from 'app/constants/rbac';
import { USER_TYPES } from 'app/constants/users';
import { useFetchLoggedInUser } from 'app/queries/useUserQueries';
import { WrappedError } from 'app/types/ApiError';
import { UserProfile } from 'app/types/users';

type LoggedInUserContextType = {
  isLoading: boolean;
  error: WrappedError | null;
  user?: UserProfile;
  isAllowed: (allowedRoles: string[]) => boolean;
  isThreatscapeUser: boolean;
  currentProduct?: PRODUCT_TYPES;
  setCurrentProduct: (product: PRODUCT_TYPES) => void;
};

const LoggedInUserContext = createContext<LoggedInUserContextType>({
  isLoading: true
} as LoggedInUserContextType);

export const LoggedInUserProvider = (props: PropsWithChildren) => {
  const { isLoading, data, error } = useFetchLoggedInUser();

  const isThreatscapeUser = useMemo(() => data?.type === USER_TYPES.THREATSCAPE, [data]);

  const [currentProduct, setCurrentProduct] = useState<PRODUCT_TYPES | undefined>(
    data?.currentProduct
  );

  useEffect(() => setCurrentProduct(data?.currentProduct), [data]);

  return (
    <LoggedInUserContext.Provider
      value={{
        isLoading,
        user: data,
        error,
        isAllowed: (allowedRoles: string[]) =>
          !isLoading && data
            ? allowedRoles.includes(concatUserTypeAndRole(data.type, data.role))
            : false,
        isThreatscapeUser,
        currentProduct,
        setCurrentProduct: (product: PRODUCT_TYPES) => setCurrentProduct(product)
      }}
    >
      {props.children}
    </LoggedInUserContext.Provider>
  );
};

export const useLoggedInUser = () => useContext(LoggedInUserContext);
