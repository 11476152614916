import { FunctionComponent } from 'react';
import { Box, Flex } from '@radix-ui/themes';
import { format } from 'date-fns';

import { SolutionIcon } from 'app/components/Controls';
import { AzureCustomerControl } from 'app/types/azureControls';
import { capitalizeText } from 'app/utils/bulletin-utils';

import { DetailsField } from './DetailsField';
import { DetailsSection } from './DetailsSection';

type AzureDetailsSectionProps = {
  control: AzureCustomerControl;
};

export const AzureDetailsSection: FunctionComponent<AzureDetailsSectionProps> = ({ control }) => {
  return (
    <DetailsSection label='Details'>
      <Box>
        <Flex gap='2' direction='column'>
          <DetailsField
            label='Last updated'
            value={control.lastUpdatedAt ? format(control.lastUpdatedAt, 'd MMM yyyy') : ''}
          />
          <DetailsField label='Control type' value={capitalizeText(control.type)} />
          <DetailsField
            label='Solution'
            value={
              control.solution && (
                <Flex as='span' align='center' gap='8px'>
                  {control.solution?.name}
                  <SolutionIcon solution={control.solution} />
                </Flex>
              )
            }
          />
          <DetailsField
            label='Benchmarks'
            value={
              control.benchmarks && control.benchmarks.map(benchmark => benchmark.name).join(', ')
            }
          />
        </Flex>
      </Box>
    </DetailsSection>
  );
};
