import { FormEvent, FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout } from '@radix-ui/themes';

import {
  FormCheckboxGroup,
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormPanel,
  FormRadioGroup
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { PRODUCT_TYPES, PRODUCT_TYPES_OPTIONS } from 'app/constants/app';
import { LICENCE_OPTIONS } from 'app/constants/customers';
import { URLS } from 'app/constants/routes';
import { useCreateCustomerMutation } from 'app/queries/useCustomerManagementQueries';
import { CreateCustomerRequest } from 'app/types/customers';
import { getMessageFromError } from 'app/utils/error-utils';

export const CreateCustomer: FunctionComponent = () => {
  const navigate = useNavigate();

  const [showCustomerLicenceError, setShowCustomerLicenceError] = useState(false);
  const [products, setProducts] = useState<string[]>([]);
  const [showProductsError, setShowProductsError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showTenantIdError, setShowTenantIdError] = useState(false);

  const { mutate, isPending, isError, error } = useCreateCustomerMutation();

  const handleCustomerLicenceChange = (val: string) => {
    setShowCustomerLicenceError(!val);
  };

  const handleProductsChange = (val: string[]) => {
    setProducts(val);
    setShowProductsError(val.length === 0);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const name = formData.get('name');
    const tenantId = formData.get('tenantId');
    const isProductSelected = products.length > 0;
    const m365Licence = formData.get('m365Licence');

    setShowNameError(!name);
    setShowTenantIdError(!tenantId);
    setShowProductsError(!isProductSelected);
    setShowCustomerLicenceError(!m365Licence);

    if (!name || !tenantId || !isProductSelected || !m365Licence) {
      return;
    }

    mutate(
      {
        customer: {
          name: name as string,
          tenantId: tenantId as string,
          products: products as PRODUCT_TYPES[],
          m365Licence: m365Licence as string
        } as unknown as CreateCustomerRequest
      },
      {
        onSuccess: () => {
          navigate(`/${URLS.ADMIN_CENTRE_CUSTOMERS}`);
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_CUSTOMERS}`);
  };

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.CUSTOMERS}>
      <FormPanel
        heading='New Customer'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
      >
        {isError && (
          <Callout.Root color='red' size='1' data-testid='customer-form-error'>
            <Callout.Icon>
              <Warning role='none' />
            </Callout.Icon>
            <Callout.Text>Unable to create customer.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}

        <FormField name='name' data-testid='customer-name-field'>
          <FormLabel>Name</FormLabel>
          <FormInput
            name='name'
            disabled={isPending}
            onChange={val => setShowNameError(val.target.value.length === 0)}
          />
          <FormMessage match='valueMissing' forceMatch={showNameError}>
            This field is required
          </FormMessage>
        </FormField>

        <FormField name='tenantId' data-testid='customer-tenantId-field'>
          <FormLabel>Tenant ID</FormLabel>
          <FormInput
            name='tenantId'
            disabled={isPending}
            onChange={val => setShowTenantIdError(val.target.value.length === 0)}
          />
          <FormMessage match='valueMissing' forceMatch={showTenantIdError}>
            This field is required
          </FormMessage>
        </FormField>

        <FormPrimitive.Field name='products' data-testid='customer-products-field'>
          <FormLabel id='customer-products-label' defaultLabel>
            Products
          </FormLabel>
          <FormCheckboxGroup
            id='customer-products'
            aria-labelledby='customer-products-label'
            name='products'
            value={products}
            disabled={isPending}
            onValueChange={handleProductsChange}
            items={PRODUCT_TYPES_OPTIONS}
          />
          <FormMessage match='valueMissing' forceMatch={showProductsError}>
            This field is required
          </FormMessage>
        </FormPrimitive.Field>

        <FormPrimitive.Field name='m365Licence' data-testid='customer-licence-field'>
          <FormLabel id='customer-licence-label' defaultLabel>
            M365 licence
          </FormLabel>
          <FormRadioGroup
            id='customer-licence-field'
            aria-labelledby='customer-licence-label'
            name='m365Licence'
            disabled={isPending}
            onInvalid={() => setShowCustomerLicenceError(true)}
            items={LICENCE_OPTIONS}
            onValueChange={handleCustomerLicenceChange}
          />
          <FormMessage match='valueMissing' forceMatch={showCustomerLicenceError}>
            This field is required
          </FormMessage>
        </FormPrimitive.Field>
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};
