import { FunctionComponent, useEffect } from 'react';
import { CheckboxGroup } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('FormCheckboxGroup').create(() => ({
  item: {
    fontSize: '14px',
    lineHeight: 1.7,
    gap: '8px',
    alignItems: 'center',
    '&>button::before': {
      boxShadow: 'inset 0 0 0 1px var(--input-border-color)'
    }
  }
}));

export const FormCheckboxGroup: FunctionComponent<
  CheckboxGroup.RootProps & {
    items: { label: string; value: string }[];
  }
> = ({ items, onValueChange, ...props }) => {
  const { classes } = useStyles();

  useEffect(() => {
    const findCheckboxInputs = document.querySelectorAll(`input[name="${props.name}"]`);
    findCheckboxInputs.forEach((input, index) => {
      input.setAttribute('id', `${props.name}-${index}`);
      input.parentElement!.setAttribute('for', `${props.name}-${index}`);
    });
  }, [props]);

  return (
    <CheckboxGroup.Root {...props} onValueChange={onValueChange} role={'group'}>
      {items.map(({ label, value }) => (
        <CheckboxGroup.Item value={value} key={value} className={classes.item} aria-label={label}>
          {label}
        </CheckboxGroup.Item>
      ))}
    </CheckboxGroup.Root>
  );
};
