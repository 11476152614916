import { FunctionComponent } from 'react';
import { Card, Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { BusinessImpactTag, SecurityRiskTag } from 'app/components';
import { PRODUCT_TYPES } from 'app/constants/app';
import { M365CustomerControl } from 'app/types';
import { AzureCustomerControl } from 'app/types/azureControls';

import { AzureDetailsSection, M365DetailsSection, StatusSection } from './DetailsSection';
import { ControlDetailsCommonProps } from './types';

const useStyles = tss.withName('ControlDetails').create(() => ({
  container: {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  details: {
    background: 'var(--white)',
    border: '1px solid var(--slate-200)',
    padding: '16px',
    boxShadow: 'none',
    flex: '0 1 349px',
    flexShrink: 0,
    '--card-border-width': '0px'
  },
  innerContainer: {
    overflow: 'auto',
    height: '100%'
  },
  info: {
    paddingBottom: '16px',
    borderBottom: '1px solid var(--slate-200)'
  },
  id: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '20px',
    lineHeight: 1.4,
    fontWeight: 700
  },
  controlNote: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    fontWeight: 400
  }
}));

export const ControlDetailsPanel: FunctionComponent<ControlDetailsCommonProps> = ({
  control,
  updateControl,
  type
}) => {
  const { classes } = useStyles();

  const detailsSectionType = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return <AzureDetailsSection control={control as AzureCustomerControl} />;
      case PRODUCT_TYPES.M365:
        return (
          <M365DetailsSection
            control={control as M365CustomerControl}
            onControlChange={updateControl}
          />
        );
    }
  };

  return (
    <Card className={classes.details} data-testid='customer-control-details' tabIndex={-1}>
      <Flex className={classes.innerContainer} direction='column' gap='2'>
        <Flex direction='column' gap='2' className={classes.info}>
          <Flex direction='column'>
            <Text as='span' className={classes.id}>
              #{control.externalControlId}
            </Text>
            <Heading as='h3' className={classes.heading}>
              {control.name}
            </Heading>
          </Flex>
          <Text as='p' className={classes.controlNote}>
            {control.controlNotes}
          </Text>
          <Flex gap='2' align='center'>
            {control.securityRisk && <SecurityRiskTag value={control.securityRisk} />}
            {control.businessRisk && <BusinessImpactTag value={control.businessRisk} />}
          </Flex>
        </Flex>
        <Flex gap='4' justify='between' direction='column'>
          <StatusSection control={control} onControlChange={updateControl} type={type} />
          {detailsSectionType()}
        </Flex>
      </Flex>
    </Card>
  );
};
