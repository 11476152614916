import { FunctionComponent } from 'react';

import {
  ImpactFilter,
  InitialFindingsFilter,
  LicensedFilter,
  NISPrinciplesFilter,
  RiskFilter,
  SolutionsFilter,
  StatusFilter
} from 'app/components/Filters';
import { PRODUCT_TYPES } from 'app/constants/app';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { useControlsContext } from 'app/hooks/useControls';

type M365FilterPanelItemsProps = {
  onFilterChange: (prop: FILTER_PROP_NAMES, value?: string | string[]) => void;
};

export const M365FilterPanelItems: FunctionComponent<M365FilterPanelItemsProps> = ({
  onFilterChange
}) => {
  const { filters } = useControlsContext();

  return (
    <>
      <StatusFilter
        defaultValue={filters.statuses}
        defaultAsOpen={!!filters.statuses}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.STATUSES, value)}
      />
      <LicensedFilter
        defaultValue={filters.licensed}
        defaultAsOpen={!!filters.licensed}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.LICENSED, value)}
      />
      <RiskFilter
        defaultValue={filters.risk}
        defaultAsOpen={!!filters.risk}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.RISK, value)}
      />
      <ImpactFilter
        defaultValue={filters.impact}
        defaultAsOpen={!!filters.impact}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.IMPACT, value)}
      />
      <InitialFindingsFilter
        defaultValue={filters.initialFindings}
        defaultAsOpen={!!filters.initialFindings}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.INITIAL_FINDINGS, value)}
      />
      <SolutionsFilter
        defaultValue={filters.solutionIds}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.SOLUTIONS, value)}
        productType={PRODUCT_TYPES.M365}
      />
      <NISPrinciplesFilter
        defaultValue={filters.nisPrinciplesIds}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.NIS_PRINCIPLES, value)}
      />
    </>
  );
};
