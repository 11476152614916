import { FunctionComponent } from 'react';
import { tss } from 'tss-react';

import { ControlCheckData, ControlCheckProperties } from 'app/types';

import { ControlCheckPropertyType } from '../ControlCheckPropertyType';

const useStyles = tss.withName('ComplexTableGroupedItems').create(() => ({
  tableCell: {
    textAlign: 'left',
    alignContent: 'flex-start',
    paddingRight: '8px'
  },
  tableRow: {
    borderBottom: '1px solid var(--slate-200)',
    '&>td:last-of-type': {
      paddingRight: '0'
    }
  },
  expandButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    color: 'var(--font-link-icon-color)',
    textDecoration: 'underline'
  }
}));

type ComplexTableGroupedItemsProps = {
  controlCheckDataItem: ControlCheckData;
  index: number;
  propertiesKeys: string[];
  controlCheckProperties: ControlCheckProperties[];
};

export const ComplexTableGroupedItems: FunctionComponent<ComplexTableGroupedItemsProps> = ({
  controlCheckDataItem,
  index,
  propertiesKeys,
  controlCheckProperties
}) => {
  const { classes } = useStyles();

  return (
    <tr key={`complex-table-row-${index}`} className={classes.tableRow}>
      <td className={classes.tableCell} />
      {propertiesKeys.map((propertyKey, index) => (
        <td key={`complex-table-row-cell-${propertyKey}-${index}`} className={classes.tableCell}>
          {
            index !== 0 &&
            Object.keys(controlCheckDataItem).find(itemKey => itemKey === propertyKey) ? (
              <ControlCheckPropertyType
                propertyType={
                  controlCheckProperties.find(property => property.name === propertyKey)!.type
                }
                value={
                  Object.entries(controlCheckDataItem).find(
                    itemKey => itemKey[0] === propertyKey
                  )?.[1] ?? ''
                }
                displayItems={
                  controlCheckProperties.find(property => property.name === propertyKey)
                    ?.displayItems
                }
              />
            ) : null //if there is no value just leave this cell empty
          }
        </td>
      ))}
    </tr>
  );
};
