import { FunctionComponent, useMemo, useState } from 'react';
import { Checkbox, Flex, Text, TextArea } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PrimaryButton, SecondaryButton } from 'app/components/Buttons';
import { PRODUCT_TYPES } from 'app/constants/app';
import { useWhatsNewContext } from 'app/hooks/useWhatsNew';
import { useDeleteMasterControlMutation as useDeleteAzureMasterControlMutation } from 'app/queries/useAzureMasterControlsQueries';
import { useDeleteMasterControlMutation as useDeleteM365MasterControlMutation } from 'app/queries/useM365MasterControlsQueries';
import { useCreateWhatsNewBulletinMutation } from 'app/queries/useWhatsNewQueries';

const useStyles = tss.withName('MasterControlDelete').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--red-a5)',
    background: 'var(--red-a4)',
    display: 'flex',
    padding: '8px'
  },
  text: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.71
  },
  button: {
    borderRadius: '6px',
    background: 'var(--red-a5)'
  },
  textarea: {
    flex: 1,
    borderRadius: '6px',
    padding: 0,
    background: 'var(--bg-inputs)',
    boxShadow: 'none',
    '&>textarea': {
      border: '1px solid var(--input-border-color)'
    }
  },
  publishBulletinText: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1
  }
}));

type DeleteTableRowProps = {
  type: PRODUCT_TYPES;
  externalControlId: string;
  setDeleteRow: React.Dispatch<React.SetStateAction<null>>;
};

export const MasterControlDelete: FunctionComponent<DeleteTableRowProps> = ({
  type,
  setDeleteRow,
  externalControlId
}) => {
  const { classes } = useStyles();
  const [publishBulletinChecked, setPublishBulletinChecked] = useState(false);
  const [deleteControlBulletinText, setDeleteControlBulletinText] = useState('Control deleted');

  const { setIsWhatsNewOpen } = useWhatsNewContext();
  const { mutate: createNewBulletin, isPending } = useCreateWhatsNewBulletinMutation();

  const useDeleteMutation = useMemo(() => {
    return type === PRODUCT_TYPES.AZURE
      ? useDeleteAzureMasterControlMutation
      : useDeleteM365MasterControlMutation;
  }, [type]);

  const { mutate: deleteMasterControl } = useDeleteMutation();

  const handleDelete = () => {
    deleteMasterControl(externalControlId, {
      onSuccess: () => {
        publishBulletinChecked &&
          !isPending &&
          createNewBulletin({
            externalControlId,
            content: deleteControlBulletinText
          });
        setDeleteRow(null);
        setIsWhatsNewOpen(true);
      }
    });
  };

  return (
    <div className={classes.container}>
      <Flex gap='4' align='center' justify='between' flexGrow='1'>
        <Flex direction='column' flexGrow='1'>
          <Text className={classes.text}>
            Control will be permanently deleted. This action cannot be undone.
          </Text>

          <Flex align='start' gap='2' data-testid='publish-bulletin-section'>
            <Checkbox
              onClick={() => setPublishBulletinChecked(!publishBulletinChecked)}
              data-testid='publish-bulletin-checkbox'
              aria-label='Publish bulletin checkbox'
            />
            <Text className={classes.publishBulletinText} weight='bold'>
              Publish bulletin:
            </Text>
            {publishBulletinChecked ? (
              <TextArea
                className={classes.textarea}
                resize='vertical'
                color='gray'
                autoFocus
                value={deleteControlBulletinText}
                onChange={e => setDeleteControlBulletinText(e.target.value)}
                data-testid='publish-bulletin-textarea'
                aria-label='Bulletin text'
              />
            ) : (
              <Text className={classes.publishBulletinText}>{deleteControlBulletinText}</Text>
            )}
          </Flex>
        </Flex>
        <Flex gap='4'>
          <SecondaryButton
            onClick={() => setDeleteRow(null)}
            data-testid='delete-cancel-button'
            title='Cancel'
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            className={classes.button}
            onClick={() => handleDelete()}
            data-testid='delete-confirm-button'
            title='Delete control'
          >
            Delete control
          </PrimaryButton>
        </Flex>
      </Flex>
    </div>
  );
};
