import { FunctionComponent } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { NAVBAR_HEIGHT, PRODUCT_TYPES } from 'app/constants/app';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { CommonFilters } from 'app/types';

import { AzureFilterPanelItems } from './AzureFilterPanelItems';
import { M365FilterPanelItems } from './M365FilterPanelItems';

const useStyles = tss.withName('FilterPanel').create(() => ({
  container: {
    flex: '1 1 25%',
    position: 'sticky',
    top: 0,
    background: 'var(--white)',
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    padding: '8px 16px 16px',
    borderRadius: 'var(--radius-4)',
    zIndex: 1,

    overflow: 'auto',
    height: 'fit-content',
    // height is: height of screen - navbar height - padding from top and bottom of page wrapper
    maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px - 32px)`
  },
  filterContainer: {
    padding: '0 8px',

    '& > *': {
      borderBottom: '1px solid var(--slate-200)',
      padding: '16px 0'
    },
    '& > *:last-child': {
      borderBottom: 'none',
      padding: '16px 0 0'
    }
  },

  title: {
    fontSize: '18px',
    lineHeight: 1.55,
    fontWeight: 700
  }
}));

type FilterPanelProps = {
  filters: CommonFilters;
  onFilterChange: (prop: FILTER_PROP_NAMES, value?: string | string[]) => void;
  type: PRODUCT_TYPES;
};

export const FilterPanel: FunctionComponent<FilterPanelProps> = ({
  filters,
  onFilterChange,
  type
}) => {
  const { classes } = useStyles();

  return (
    <Flex direction='column' className={classes.container} flexGrow='1' data-testid='filters-panel'>
      <Flex direction='column' gap='2'>
        <Text className={classes.title}>Filters</Text>
      </Flex>
      <Flex direction='column' className={classes.filterContainer}>
        {type === PRODUCT_TYPES.AZURE && (
          <AzureFilterPanelItems onFilterChange={onFilterChange} filters={filters} />
        )}
        {type === PRODUCT_TYPES.M365 && (
          <M365FilterPanelItems onFilterChange={onFilterChange} filters={filters} />
        )}
      </Flex>
    </Flex>
  );
};
