import { FunctionComponent, useMemo } from 'react';
import { Flex, Heading, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { WrappedError } from 'app/types/ApiError';
import { getMessageFromError } from 'app/utils/error-utils';

import { PageWrapper } from '../PageWrappers';

const useStyles = tss.withName('UnauthorisedPage').create(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '48px',
    width: '100%'
  }
}));

type UnauthorisedPageProps = {
  error?: WrappedError | null;
};

export const UnauthorisedSection: FunctionComponent<UnauthorisedPageProps> = ({ error }) => {
  const { classes } = useStyles();

  const errorMessage = useMemo(() => getMessageFromError(error), [error]);

  return (
    <PageWrapper>
      <Flex data-testid='unauthorised' className={classes.container}>
        <Heading>Unauthorised Access</Heading>
        <Text as='p'>{errorMessage ?? 'You do not have permission to view this page.'}</Text>
      </Flex>
    </PageWrapper>
  );
};

export const UnauthorisedPage: FunctionComponent<UnauthorisedPageProps> = ({ error }) => (
  <PageWrapper>
    <UnauthorisedSection error={error} />
  </PageWrapper>
);
