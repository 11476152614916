import { FC, PropsWithChildren } from 'react';
import { tss } from 'tss-react';

const useStyles = tss
  .withName('PageWrapper')
  .withParams<{ withSidePadding?: boolean }>()
  .create(({ withSidePadding }) => ({
    container: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      overflow: 'auto',
      padding: withSidePadding ? '16px 16px 16px 48px' : '16px',
      height: 'calc(100% - 32px)', // 32px = top padding + bottom padding
      background: 'var(--bg-default)',
      position: 'relative'
    }
  }));

export const PageWrapper: FC<
  {
    withSidePadding?: boolean;
  } & PropsWithChildren
> = ({ withSidePadding, children }) => {
  const { classes } = useStyles({ withSidePadding });
  return <main className={classes.container}>{children}</main>;
};
