import { FunctionComponent } from 'react';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { Button } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { ControlCheckData, ControlCheckProperties } from 'app/types';

import { ComplexTableGroupedItems } from './ComplexTableGroupedItems';

const useStyles = tss.withName('ComplexTableGroupingRow').create(() => ({
  tableRow: {
    borderBottom: '1px solid var(--slate-200)',
    '&:last-of-type': {
      borderBottom: 0
    },
    '&>td:last-of-type': {
      paddingRight: '0'
    }
  },
  groupedRowLabel: {
    fontSize: '12px',
    lineHeight: 1.67,
    fontWeight: 700,
    color: 'var(--font-secondary-color)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%'
  },
  expandButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    color: 'var(--font-link-icon-color)',
    textDecoration: 'underline'
  },
  expandSingleButton: {
    cursor: 'pointer',
    color: 'var(--font-primary-color)',
    verticalAlign: 'baseline',
    textAlign: 'center'
  },
  expandButtonCell: {
    width: '30px'
  }
}));

type ComplexTableGroupingRowProps = {
  controlCheckDataItem: ControlCheckData[];
  index: number;
  controlCheckProperties: ControlCheckProperties[];
  toggleItemRow: (index: number) => void;
  expandedItems: boolean[];
};

export const ComplexTableGroupingRow: FunctionComponent<ComplexTableGroupingRowProps> = ({
  controlCheckDataItem,
  index,
  controlCheckProperties,
  expandedItems,
  toggleItemRow
}) => {
  const { classes } = useStyles();
  const propertiesKeys = controlCheckProperties.flatMap(property => property.name);

  const groupingPropertyLabel = controlCheckDataItem![0][propertiesKeys[0]];

  return (
    <>
      <tr key={`complex-table-grouped-row-${index}`} className={classes.tableRow}>
        <td className={classes.expandButtonCell}>
          <Button
            variant='ghost'
            size='1'
            color='gray'
            onClick={() => toggleItemRow(index)}
            className={classes.expandSingleButton}
            title={expandedItems[index] ? 'Collapse group' : 'Expand group'}
            data-testid={`complex-table-expand-button-${index}`}
          >
            {expandedItems[index] ? (
              <CaretUp size={12} role='none' />
            ) : (
              <CaretDown size={12} role='none' />
            )}
          </Button>
        </td>
        <td className={classes.groupedRowLabel}>{groupingPropertyLabel}</td>
      </tr>
      {expandedItems[index] &&
        controlCheckDataItem!.map((controlCheckItem, index) => (
          <ComplexTableGroupedItems
            controlCheckDataItem={controlCheckItem}
            index={index}
            propertiesKeys={propertiesKeys}
            controlCheckProperties={controlCheckProperties}
            key={`complex-table-row-${index}`}
          />
        ))}
    </>
  );
};
