import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { DashboardStatusIcon } from 'app/components/Dashboard';
import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_URLS_BY_PRODUCT } from 'app/constants/controls';
import { AzureDashboardControl, M365DashboardControl } from 'app/types/dashboard';

import { DashboardStatusTooltip } from '../DashboardStatusTooltip';

const useStyles = tss.withName('ControlsReport').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--white)',
    padding: '16px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    color: 'var(--font-primary-color)'
  },
  currentNumber: {
    color: 'var(--font-primary-color)',
    fontSize: '41px',
    lineHeight: '41px'
  },
  total: {
    fontSize: '16px',
    lineHeight: '1.06',
    fontWeight: 900
  },
  statusIconLink: {
    display: 'flex'
  }
}));

type ControlsReportProps = {
  dashboardControls: Array<M365DashboardControl | AzureDashboardControl>;
  totalControls: number;
  type: PRODUCT_TYPES;
};

export const ControlsReport: FunctionComponent<ControlsReportProps> = ({
  dashboardControls,
  totalControls,
  type
}) => {
  const { classes } = useStyles();

  return (
    <Box pt='4' width='100%' className={classes.container} data-testid='controls'>
      <Flex gap='5' direction='column'>
        <Flex gap='4' align='center'>
          <Text className={classes.currentNumber}>{dashboardControls.length}</Text>
          <Text className={classes.total}>
            of {totalControls}
            <br />
            controls
          </Text>
        </Flex>
        <Flex wrap='wrap' align='center' gapY='2'>
          {dashboardControls.length === 0 && <Text>Please try another filter combination.</Text>}
          {dashboardControls.map((status, index) => (
            <DashboardStatusTooltip control={status} key={index}>
              <Link
                to={`/${CONTROL_URLS_BY_PRODUCT[type].controlDetailsLink.replace(':id', status.externalControlId)}`}
                className={classes.statusIconLink}
                data-testid='controls-status-link'
              >
                <DashboardStatusIcon status={status.status} />
              </Link>
            </DashboardStatusTooltip>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
