import { PRODUCT_TYPES } from './app';

export enum URLS {
  DASHBOARDS = 'dashboards',
  CONTROLS = 'controls',
  M365_DASHBOARDS = 'm-365/dashboards',
  M365_CONTROLS = 'm-365/controls',
  M365_CONTROL_DETAILS = 'm-365/controls/:id',
  AZURE_DASHBOARDS = 'azure/dashboards',
  AZURE_CONTROLS = 'azure/controls',
  AZURE_CONTROL_DETAILS = 'azure/controls/:id',
  ADMIN_ROOT = 'admin-centre',
  ADMIN_CENTRE_USERS = 'admin-centre/users',
  ADMIN_CENTRE_USERS_CREATE = 'admin-centre/users/create',
  ADMIN_CENTRE_USERS_EDIT = 'admin-centre/users/:id/edit',
  ADMIN_CENTRE_CUSTOMERS = 'admin-centre/customers',
  ADMIN_CENTRE_CUSTOMERS_CREATE = 'admin-centre/customers/create',
  ADMIN_CENTRE_CUSTOMERS_EDIT = 'admin-centre/customers/:id/edit',
  ADMIN_CENTRE_M365_CONTROLS = 'admin-centre/m-365/controls',
  ADMIN_CENTRE_M365_CONTROLS_CREATE = 'admin-centre/m-365/controls/create',
  ADMIN_CENTRE_M365_CONTROLS_EDIT = 'admin-centre/m-365/controls/:id/edit',
  ADMIN_CENTRE_M365_CONTROLS_BULLETIN_CREATE = 'admin-centre/m-365/controls/create/bulletin',
  ADMIN_CENTRE_M365_CONTROLS_BULLETIN_EDIT = 'admin-centre/m-365/controls/:id/bulletin',
  ADMIN_CENTRE_AZURE_CONTROLS = 'admin-centre/azure/controls',
  ADMIN_CENTRE_AZURE_CONTROLS_CREATE = 'admin-centre/azure/controls/create',
  ADMIN_CENTRE_AZURE_CONTROLS_EDIT = 'admin-centre/azure/controls/:id/edit',
  ADMIN_CENTRE_AZURE_CONTROLS_BULLETIN_CREATE = 'admin-centre/azure/controls/create/bulletin',
  ADMIN_CENTRE_AZURE_CONTROLS_BULLETIN_EDIT = 'admin-centre/azure/controls/:id/bulletin',
  AUTOMATED_ASSESSMENT_AUTHORISED = 'automated-assessment/authorised'
}

export const URLS_BY_PRODUCT_TYPE = {
  [PRODUCT_TYPES.AZURE]: {
    DASHBOARD_URL: URLS.AZURE_DASHBOARDS,
    CONTROLS_URL: URLS.AZURE_CONTROLS
  },
  [PRODUCT_TYPES.M365]: {
    DASHBOARD_URL: URLS.M365_DASHBOARDS,
    CONTROLS_URL: URLS.M365_CONTROLS
  }
};

export const M365_DASHBOARD_CONTROLS_API_ROOT = {
  ROOT: '/m-365/dashboard-controls'
};

export const AZURE_DASHBOARD_CONTROLS_API_ROOT = {
  ROOT: '/azure/dashboard-controls'
};

export const M365_CONTROLS_API_URLS = {
  ROOT: '/m-365/customer-controls',
  SINGLE: '/m-365/customer-controls/:controlId',
  STATUS: '/m-365/customer-controls/:controlId/status',
  CONTROL_POSTURE: '/m-365/customer-controls/:controlId/control-posture',
  LICENCED: '/m-365/customer-controls/:controlId/licenced',
  NOTES: '/m-365/customer-controls/:controlId/notes',
  CONTROL_CHECKS: '/m-365/customer-controls/:controlId/checks'
};

export const AZURE_CONTROLS_API_URLS = {
  ROOT: '/azure/customer-controls',
  SINGLE: '/azure/customer-controls/:controlId',
  STATUS: '/azure/customer-controls/:controlId/status',
  CONTROL_POSTURE: '/azure/customer-controls/:controlId/control-posture',
  NOTES: '/azure/customer-controls/:controlId/notes',
  CONTROL_CHECKS: '/azure/customer-controls/:controlId/checks'
};

export const M365_SOLUTIONS_API_URLS = {
  ROOT: '/m-365/solutions/all'
};

export const M365_NIS_PRINCIPLES_API_URLS = {
  ROOT: '/m-365/nis-principles/all'
};

export const AZURE_SOLUTIONS_API_URLS = {
  ROOT: '/azure/solutions/all'
};

export const AZURE_BENCHMARKS_API_URLS = {
  ROOT: '/azure/benchmarks/all'
};

export const USERS_API_URLS = {
  ROOT: '/users',
  LOGGED_IN_USER: '/users/logged-in-user',
  SINGLE: '/users/:userId'
};

export const CUSTOMERS_API_URLS = {
  ROOT: '/customers',
  ALL: '/customers/all',
  SINGLE: '/customers/:id'
};

export const M365_MASTER_CONTROLS_API_URLS = {
  ROOT: '/m-365/master-controls',
  SINGLE: '/m-365/master-controls/:id'
};

export const AZURE_MASTER_CONTROLS_API_URLS = {
  ROOT: '/azure/master-controls',
  SINGLE: '/azure/master-controls/:id'
};

export const WHATS_NEW_API_URLS = {
  ROOT: '/whats-new',
  CREATE: '/whats-new/bulletins',
  SINGLE: '/whats-new/bulletins/:id'
};
