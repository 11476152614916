import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { AUTH_INSTANCE } from './constants';

let FORCE_REFRESH = true;

export const getAccessToken = async () => {
  // if mocking, then return empty string
  if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_DISABLE_AUTH === 'true') return '';

  // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const activeAccount = AUTH_INSTANCE.getActiveAccount();
  const accounts = AUTH_INSTANCE.getAllAccounts();
  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }

  const request = {
    scopes: ['openid', 'profile', 'offline_access'],
    account: activeAccount || accounts[0],
    forceRefresh: FORCE_REFRESH
  };

  const val = await AUTH_INSTANCE.acquireTokenSilent(request).catch(async error => {
    if (error instanceof InteractionRequiredAuthError) {
      FORCE_REFRESH = true;
      // fallback to interaction when silent call fails
      await AUTH_INSTANCE.acquireTokenRedirect(request);
    }
    throw new Error(error || 'Unable to obtain access token');
  });

  // we only want forceRefresh to be true on pageLoad - for subsequent calls it should be false
  if (FORCE_REFRESH) FORCE_REFRESH = false;

  return val.idToken;
};
