import { FunctionComponent, useMemo } from 'react';

import { CONTROL_CHECKS_IS_COMPLIANT } from 'app/constants/controls';
import { ReactComponent as CompliantIcon } from 'assets/status-icons/completed.svg';
import { ReactComponent as NotCompliantIcon } from 'assets/status-icons/not-compliant.svg';
import { ReactComponent as NotRequiredIcon } from 'assets/status-icons/not-required.svg';

type ControlCheckStatusIconProps = {
  status: CONTROL_CHECKS_IS_COMPLIANT;
};

export const ControlCheckStatusIcon: FunctionComponent<ControlCheckStatusIconProps> = ({
  status
}) => {
  const [SVGComponent, alt, color] = useMemo(() => {
    switch (status) {
      case CONTROL_CHECKS_IS_COMPLIANT.COMPLIANT:
        return [CompliantIcon, 'Compliant', 'var(--rag-dark-green)'];
      case CONTROL_CHECKS_IS_COMPLIANT.NOT_COMPLIANT:
        return [NotCompliantIcon, 'Not compliant', 'var(--rag-red)'];
      case CONTROL_CHECKS_IS_COMPLIANT.NOT_APPLICABLE:
      default:
        return [NotRequiredIcon, 'Not applicable/required', 'var(--rag-dull)'];
    }
  }, [status]);

  return (
    <SVGComponent
      role='img'
      aria-label={alt}
      title={alt}
      color={color}
      data-testid='compliance-status-icon'
      width={24}
      height={24}
    />
  );
};
