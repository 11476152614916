import { FunctionComponent, useState } from 'react';
import { CaretDown, CaretUp, Link } from '@phosphor-icons/react';
import { Flex, IconButton, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { CONTROL_CHECKS_DISPLAY_TYPES } from 'app/constants/controls';
import { CustomerControlCheck } from 'app/types';

import {
  ControlCheckTypeComplexTable,
  ControlCheckTypeForm,
  ControlCheckTypeTable
} from './ControlCheckDisplayTypes';
import { ControlCheckStatusIcon } from './ControlCheckStatusIcons';

const useStyles = tss.withName('ControlCheck').create(() => ({
  container: {
    padding: '16px 0',
    borderBottom: '1px solid var(--slate-200)'
  },
  checkTitle: {
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700,
    flexWrap: 'wrap'
  },
  complianceStatus: {
    flexShrink: 0
  },
  showInfoButton: {
    cursor: 'pointer',
    color: 'var(--font-primary-color)'
  },
  defaultOutputText: {
    fontSize: '12px',
    lineHeight: 1.67,
    fontWeight: 700,
    color: 'var(--font-secondary-color)'
  }
}));

type ControlCheckProps = {
  controlCheck: CustomerControlCheck;
  index: number;
};

export const ControlCheck: FunctionComponent<ControlCheckProps> = ({ controlCheck, index }) => {
  const { classes } = useStyles();
  const [isInfoOpened, setIsInfoOpened] = useState(false);

  const controlCheckDisplayType = (displayType: CONTROL_CHECKS_DISPLAY_TYPES | string) => {
    switch (displayType) {
      case CONTROL_CHECKS_DISPLAY_TYPES.FORM:
        return <ControlCheckTypeForm controlCheck={controlCheck} />;
      case CONTROL_CHECKS_DISPLAY_TYPES.TABLE:
        return <ControlCheckTypeTable controlCheck={controlCheck} />;
      case CONTROL_CHECKS_DISPLAY_TYPES.COMPLEX_TABLE:
        return <ControlCheckTypeComplexTable controlCheck={controlCheck} />;
      //If the displayType is unrecognised/unknown show the defaultOutput
      default:
        return <Text className={classes.defaultOutputText}>{controlCheck.defaultOutput}</Text>;
    }
  };

  return (
    <Flex
      justify='between'
      gap='4'
      className={classes.container}
      data-testid={`control-check-${index}`}
    >
      <Flex gap='2' direction='column' flexGrow='1'>
        <Flex gap='2' justify='between' align='center'>
          <Flex gap='2'>
            <Text
              className={classes.checkTitle}
              data-testid={`control-check-${index}-title`}
            >{`${index + 1}. ${controlCheck.displayName}`}</Text>
            {controlCheck.interactiveUrls &&
              controlCheck.interactiveUrls.map((url, i) => (
                <Flex key={i}>
                  <a href={url} target='_blank' rel='noreferrer'>
                    <Link size={20} color='var(--font-link-icon-color)' />
                  </a>
                </Flex>
              ))}
          </Flex>
          <IconButton
            variant='ghost'
            onClick={() => setIsInfoOpened(!isInfoOpened)}
            title={isInfoOpened ? 'Hide info' : 'Show info'}
            className={classes.showInfoButton}
            data-testid={`control-check-${index}-show-info-button`}
          >
            {isInfoOpened ? <CaretUp size={16} role='none' /> : <CaretDown size={16} role='none' />}
          </IconButton>
        </Flex>
        {isInfoOpened && (
          <div data-testid={`control-check-${index}-info`}>
            {controlCheck.checkData && controlCheck.checkData.length > 0 ? (
              controlCheckDisplayType(controlCheck.displayType)
            ) : (
              <Text className={classes.defaultOutputText}>{controlCheck.defaultOutput}</Text>
            )}
          </div>
        )}
      </Flex>
      <span className={classes.complianceStatus}>
        <ControlCheckStatusIcon status={controlCheck.isCompliant} />
      </span>
    </Flex>
  );
};
