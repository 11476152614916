import { FunctionComponent } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { CustomerControlCheck } from 'app/types';
import { controlCheckCheckDataObject } from 'app/utils/control-utils';

import { ControlCheckPropertyType } from './ControlCheckPropertyType';

const useStyles = tss.withName('ControlCheckTypeForm').create(() => ({
  container: {
    width: 'fit-content'
  },
  formItem: {
    borderBottom: '1px solid var(--slate-200)',
    color: 'var(--font-secondary-color)',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  formItemLabel: {
    fontSize: '12px',
    lineHeight: 1.67,
    fontWeight: 700,
    flex: '0 1 144px'
  },
  formItemValue: {
    fontSize: '12px',
    lineHeight: 1.67,
    fontWeight: 400
  }
}));

type ControlCheckProps = {
  controlCheck: CustomerControlCheck;
};

export const ControlCheckTypeForm: FunctionComponent<ControlCheckProps> = ({ controlCheck }) => {
  const { classes } = useStyles();

  return (
    <Flex justify='between' gap='1' className={classes.container} direction='column'>
      {controlCheck.properties.map(property => (
        <Flex key={property.name} className={classes.formItem} gap='4'>
          <Text className={classes.formItemLabel}>{property.displayName}</Text>
          {controlCheckCheckDataObject(controlCheck)![property.name] !== undefined && (
            <ControlCheckPropertyType
              propertyType={property.type}
              value={controlCheckCheckDataObject(controlCheck)[property.name]}
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};
