import { FunctionComponent } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { CustomerControlCheck } from 'app/types';

import { ControlCheckPropertyType } from './ControlCheckPropertyType';

const useStyles = tss.withName('ControlCheckTypeTable').create(() => ({
  tableRowHeader: {
    borderBottom: '1px solid var(--slate-200)',
    color: 'var(--font-secondary-color)',
    '&:last-of-type': {
      borderBottom: 'none'
    },
    '&>th:last-of-type': {
      paddingRight: '0'
    }
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: '8px',
    borderCollapse: 'collapse'
  },

  tableHeaderCell: {
    fontSize: '12px',
    lineHeight: 1.67,
    fontWeight: 700,
    color: 'var(--font-secondary-color)'
  },
  tableCell: {
    textAlign: 'left',
    alignContent: 'flex-start',
    borderBottom: '1px solid var(--slate-200)',
    paddingRight: '8px'
  },
  tableRow: {
    '&>td:last-of-type': {
      paddingRight: '0'
    }
  },
  formItemLabelFirst: {
    fontWeight: 700
  }
}));

type ControlCheckProps = {
  controlCheck: CustomerControlCheck;
};

export const ControlCheckTypeTable: FunctionComponent<ControlCheckProps> = ({ controlCheck }) => {
  const { classes } = useStyles();

  const objectKeysThatMatchProperties = controlCheck.properties.flatMap(property => property.name);

  return (
    <Flex justify='between' gap='1' direction='column'>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableRowHeader}>
            {controlCheck.properties.map((property, index) => (
              <th
                key={`${property.name}-${index}`}
                className={classes.tableCell}
                data-testid={`table-header-cell-${index}`}
              >
                {index !== 0 && (
                  <Text className={classes.tableHeaderCell}>{property.displayName}</Text>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {controlCheck.checkData?.map((item, index) => (
            <tr key={`table-row-${index}`} className={classes.tableRow}>
              {objectKeysThatMatchProperties.map((propertyKey, index) => (
                <td key={`table-row-cell-${propertyKey}-${index}`} className={classes.tableCell}>
                  {
                    Object.keys(item).find(itemKey => itemKey === propertyKey) ? (
                      <div className={clsx(index === 0 && classes.formItemLabelFirst)}>
                        <ControlCheckPropertyType
                          propertyType={
                            controlCheck.properties.find(property => property.name === propertyKey)!
                              .type
                          }
                          value={
                            Object.entries(item).find(itemKey => itemKey[0] === propertyKey)?.[1] ??
                            ''
                          }
                          displayItems={
                            controlCheck.properties.find(property => property.name === propertyKey)
                              ?.displayItems
                          }
                        />
                      </div>
                    ) : null //if there is no value just leave this cell empty
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Flex>
  );
};
