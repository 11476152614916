import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import { Callout } from '@radix-ui/themes';
import { tss } from 'tss-react';

import {
  CustomerControlCard,
  EmptyResult,
  PageLoadingIndicator,
  PageWrapper,
  SwitchProduct,
  UnauthorisedPage
} from 'app/components';
import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_SORT_OPTIONS } from 'app/constants/controls';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { useControlsContext } from 'app/hooks/useControls';
import {
  buildFiltersFromSearchParams,
  updateSearchParamsWithFilter
} from 'app/utils/control-utils';

import { FilterPanel } from './Filters/FilterPanel';

const useStyles = tss.withName('ControlsList').create(() => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 818px',
    maxWidth: '818px',
    gap: '16px',
    minWidth: 0
  },
  bottomSpace: {
    height: '1px',
    flex: 'none'
  },
  filters: {
    flex: '1 1 246px',
    position: 'sticky',
    top: 0,
    height: 'fit-content',
    maxWidth: '246px',
    zIndex: 1
  },
  error: {
    border: '1px solid var(--accent-a6)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)'
  },
  empty: {
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)'
  }
}));

type ControlsProps = { type: PRODUCT_TYPES };

export const Controls: FunctionComponent<ControlsProps> = ({ type }) => {
  const { classes } = useStyles();
  // get any filters from urlsearchparams
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedControl, setSelectedControl] = useState<string | undefined>();
  const itemRefs = useRef<Record<string, HTMLDivElement | null>>({});

  const { filteredData, isFetching, error, setFilters, setSort, ready, setReady } =
    useControlsContext();

  const handleFilterChange = (prop: FILTER_PROP_NAMES, value?: string | string[]) => {
    // this will also trigger the useEffect below which calls setFilters
    setSearchParams(updateSearchParamsWithFilter(searchParams, prop, value));
  };

  const handleSortChange = (newSort: string) => {
    searchParams.set('sort', newSort);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setFilters(buildFiltersFromSearchParams(searchParams));
    setSort(searchParams.get('sort') || CONTROL_SORT_OPTIONS[0].value);

    const controlId = searchParams.get('controlId');
    if (controlId) {
      setSelectedControl(controlId);
      itemRefs.current[controlId]?.scrollIntoView();
    } else {
      setSelectedControl(undefined);
    }
    setReady(true);
  }, [searchParams, setFilters, setReady, setSearchParams, setSort]);

  const showUnauthError = useMemo(() => error?.status && error.status === 401, [error]);

  return (
    <PageWrapper withSidePadding={true}>
      <SwitchProduct />
      {showUnauthError ? (
        <UnauthorisedPage error={error} />
      ) : (
        <>
          <aside className={classes.filters} aria-label='Filters'>
            {ready && (
              <FilterPanel
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
                type={type}
              />
            )}
          </aside>
          <div className={classes.main}>
            {isFetching && <PageLoadingIndicator />}
            {!isFetching && !error && filteredData?.length === 0 && (
              <EmptyResult content={'Please try another search term or filter combination.'} />
            )}
            {!isFetching &&
              filteredData?.map(val => (
                <CustomerControlCard
                  key={val.externalControlId}
                  control={val}
                  isActiveCard={selectedControl === val.externalControlId}
                  ref={el => {
                    itemRefs.current[val.externalControlId] = el;
                  }}
                  type={type}
                />
              ))}
            {!isFetching && error && (
              <Callout.Root
                color='red'
                role='alert'
                variant='surface'
                size='3'
                className={classes.error}
                data-testid='controls-error'
              >
                <Callout.Icon>
                  <Warning size={32} weight='fill' role='none' />
                </Callout.Icon>
                <Callout.Text>An unexpected error occurred.</Callout.Text>
                <Callout.Text>Please try refreshing the page.</Callout.Text>
              </Callout.Root>
            )}
            <div className={classes.bottomSpace}></div>
          </div>
        </>
      )}
    </PageWrapper>
  );
};
