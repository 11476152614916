import { Fragment, FunctionComponent, useMemo } from 'react';
import { Button, Flex, Spinner, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PRODUCT_TYPES } from 'app/constants/app';
import { useFetchCustomerControlNotes as useFetchAzureCustomerControlNotes } from 'app/queries/useAzureControlQueries';
import { useFetchCustomerControlNotes as useFetchM365CustomerControlNotes } from 'app/queries/useM365ControlQueries';
import { ControlNote } from 'app/types';

import { AddNote } from './AddNote';
import { Note } from './Note';

const useStyles = tss.withName('NotesList').create(() => ({
  container: {
    color: 'var(--font-primary-color)',
    padding: '8px 0 0'
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700
  },
  error: {
    fontSize: '14px',
    lineHeight: 1.7,
    fontStyle: 'italic',
    color: 'var(--font-secondary-color)',
    margin: 0
  },
  loadMore: {
    cursor: 'pointer',
    margin: 0
  }
}));

type NotesListProps = {
  controlId: string;
  onNotesChange: (newNote: ControlNote) => void;
  type: PRODUCT_TYPES;
};

export const NotesList: FunctionComponent<NotesListProps> = ({
  controlId,
  onNotesChange,
  type
}) => {
  const { classes } = useStyles();

  const productRelatedControlNotesQuery = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return useFetchAzureCustomerControlNotes(true, controlId);
      case PRODUCT_TYPES.M365:
        return useFetchM365CustomerControlNotes(true, controlId);
    }
  };

  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, isError } =
    productRelatedControlNotesQuery();

  const hasData = useMemo(() => data && data.pages.find(val => val.content.length), [data]);

  return (
    <Flex className={classes.container} direction='column' gap='2'>
      <Text as='p' className={classes.heading}>
        Notes
      </Text>
      <AddNote controlId={controlId} onNotesChange={onNotesChange} type={type} />
      {hasData && data && (
        <Flex className={classes.container} direction='column' gap='2' data-testid='notes-list'>
          {data?.pages.map((group, i) => (
            <Fragment key={i}>
              {group.content.map(note => (
                <Note key={note.id} note={note} />
              ))}
            </Fragment>
          ))}
        </Flex>
      )}
      {isFetching && (
        <Flex direction='column' align='center'>
          <Spinner size='3' />
        </Flex>
      )}
      {(isError || !hasData) && (
        <Flex direction='column' align='start' data-testid='empty-notes'>
          <p className={classes.error}>No notes here yet.</p>
        </Flex>
      )}
      {hasNextPage && (
        <Button
          variant='ghost'
          className={classes.loadMore}
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage || isFetching}
        >
          {isFetchingNextPage ? 'Loading more...' : 'Load more'}
        </Button>
      )}
    </Flex>
  );
};
