import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { ArrowSquareOut } from '@phosphor-icons/react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { DashboardStatusIcon } from 'app/components/Dashboard';
import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_URLS_BY_PRODUCT } from 'app/constants/controls';
import { DASHBOARD_STATUS, DASHBOARD_STATUS_LABELS, RISK_VALUE } from 'app/constants/dashboard';
import { buildSearchParamsFromFilters } from 'app/utils/control-utils';

const useStyles = tss.withName('ActionCardLink').create(() => ({
  link: {
    color: 'var(--font-primary-color)',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  },
  actionText: {
    color: 'var(--font-primary-color)',
    fontSize: '16px',
    lineHeight: 1.75
  }
}));

type SolutionsProps = {
  status: keyof typeof DASHBOARD_STATUS;
  actionLength: number;
  type: PRODUCT_TYPES;
  risk?: keyof typeof RISK_VALUE;
  impact?: keyof typeof RISK_VALUE;
};

export const ActionCardLink: FunctionComponent<SolutionsProps> = ({
  status,
  risk,
  impact,
  actionLength,
  type
}) => {
  const { classes } = useStyles();

  return (
    <Link
      to={`/${CONTROL_URLS_BY_PRODUCT[type].controlListLink}?${buildSearchParamsFromFilters({ statuses: [status], risk: risk ? [risk] : [], impact: impact ? [impact] : [] })}`}
      className={classes.link}
    >
      <Flex align={'center'} gap={'2'} justify={'start'}>
        <DashboardStatusIcon status={DASHBOARD_STATUS[status]} size={24} />
        <Text className={classes.actionText}>
          {actionLength} {DASHBOARD_STATUS_LABELS[status].toLowerCase()}
        </Text>
        <ArrowSquareOut role='none' />
      </Flex>
    </Link>
  );
};
