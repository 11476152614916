import { FunctionComponent, ReactNode } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('ControlDetailsField').create(() => ({
  label: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667
  },
  value: {
    color: 'var(--font-primary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    textAlign: 'right'
  }
}));

type DetailsFieldProps = {
  label: string;
  value?: ReactNode;
};

export const DetailsField: FunctionComponent<DetailsFieldProps> = ({ label, value }) => {
  const { classes } = useStyles();

  return (
    <Flex gap='2' justify={'between'}>
      <Text as='p' className={classes.label}>
        {label}
      </Text>
      <Text as='p' className={classes.value}>
        {value || ''}
      </Text>
    </Flex>
  );
};
