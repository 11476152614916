import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { CUSTOMERS_API_URLS } from 'app/constants/routes';
import { WrappedError } from 'app/types/ApiError';
import { SimpleCustomer } from 'app/types/customers';
import { catchApiError } from 'app/utils/error-utils';

export const USE_FETCH_ALL_CUSTOMERS_QUERY_KEY = 'useFetchCustomers';
export const useFetchCustomers = (enabled = false) => {
  return useQuery<SimpleCustomer[], WrappedError>({
    queryKey: [USE_FETCH_ALL_CUSTOMERS_QUERY_KEY],
    queryFn: () =>
      axiosInstance
        .get<SimpleCustomer[]>(CUSTOMERS_API_URLS.ALL)
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
};
