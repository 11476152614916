import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { USERS_API_URLS } from 'app/constants/routes';
import { CreateUserRequest, PagedResponse, UpdateUserRequest, User, WrappedError } from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

type UseFetchUsersOptions = {
  page?: number;
  size?: number;
  sort?: string;
  freeText?: string;
  enabled?: boolean;
};

export const USE_FETCH_USER_LIST_QUERY_KEY = 'useFetchUserList';
export const useFetchUserList = ({
  page = 0,
  size = 20,
  sort = 'name,desc',
  freeText = '',
  enabled = true
}: UseFetchUsersOptions) =>
  useQuery({
    queryKey: [USE_FETCH_USER_LIST_QUERY_KEY, { page, size, sort, freeText }],
    queryFn: () =>
      axiosInstance
        .get<PagedResponse<User>>(USERS_API_URLS.ROOT, {
          params: { page, size, sort, freeText }
        })
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });

const USE_FETCH_USER_QUERY_KEY = 'useFetchUser';
export const useFetchUser = (enabled = false, id: string) =>
  useQuery<User, WrappedError>({
    queryKey: [USE_FETCH_USER_QUERY_KEY, id],
    queryFn: () =>
      axiosInstance
        .get<User>(USERS_API_URLS.SINGLE.replace(':userId', id))
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });

export interface CreateUserMutationOptions {
  user: CreateUserRequest;
}
export const useCreateUserMutation = () =>
  useMutation<User, WrappedError, CreateUserMutationOptions>({
    mutationKey: ['useCreateUserMutation'],
    mutationFn: async ({ user }: CreateUserMutationOptions) =>
      await axiosInstance
        .post<User>(USERS_API_URLS.ROOT, { ...user, name: user.name.trim() })
        .then(response => response.data)
        .catch(catchApiError)
  });

export interface UpdateUserMutationOptions {
  userId: number;
  user: UpdateUserRequest;
}
export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<User, WrappedError, UpdateUserMutationOptions>({
    mutationKey: ['useUpdateUserMutation'],
    mutationFn: async ({ userId, user }: UpdateUserMutationOptions) =>
      await axiosInstance
        .put<User>(USERS_API_URLS.SINGLE.replace(':userId', `${userId}`), {
          ...user,
          name: user.name.trim()
        })
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_USER_LIST_QUERY_KEY, USE_FETCH_USER_QUERY_KEY]
      })
  });
};

export const useDeleteUserMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useDeleteUser'],
    mutationFn: async (userId: number) =>
      await axiosInstance
        .delete(USERS_API_URLS.SINGLE.replace(':userId', `${userId}`))
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [USE_FETCH_USER_LIST_QUERY_KEY] })
  });
};
