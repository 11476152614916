import { useMemo } from 'react';
import { Grid } from '@radix-ui/themes';

import { PRODUCT_TYPES } from 'app/constants/app';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { AzureDashboardControl, M365DashboardControl } from 'app/types';

import { ActionCard } from './ActionCard';
import { ActionCardLink } from './ActionCardLink';

const filterByStatus = (
  controls: Array<M365DashboardControl | AzureDashboardControl>,
  status: DASHBOARD_STATUS
) => controls.filter(control => control.status === status);

type SolutionsProps = {
  dashboardControls: Array<M365DashboardControl | AzureDashboardControl>;
  type: PRODUCT_TYPES;
};

export const ActionCards = ({ dashboardControls, type }: SolutionsProps) => {
  const filteredData = useMemo(() => {
    const incompleteCards = dashboardControls.filter(
      control =>
        control.status === DASHBOARD_STATUS.IN_PROGRESS ||
        control.status === DASHBOARD_STATUS.NOT_STARTED
    );
    const highRisk = incompleteCards.filter(control => control.securityRisk === 'HIGH');
    const quickWins = highRisk.filter(control => control.businessRisk === 'LOW');

    return {
      totalIncomplete: incompleteCards.length,
      numIncompleteInProgress: filterByStatus(incompleteCards, DASHBOARD_STATUS.IN_PROGRESS).length,
      numIncompleteNotStarted: filterByStatus(incompleteCards, DASHBOARD_STATUS.NOT_STARTED).length,

      totalHighRisk: highRisk.length,
      numHighRiskInProgress: filterByStatus(highRisk, DASHBOARD_STATUS.IN_PROGRESS).length,
      numHighRiskNotStarted: filterByStatus(highRisk, DASHBOARD_STATUS.NOT_STARTED).length,

      totalQuickWins: quickWins.length,
      numQuickWinsInProgress: filterByStatus(quickWins, DASHBOARD_STATUS.IN_PROGRESS).length,
      numQuickWinsNotStarted: filterByStatus(quickWins, DASHBOARD_STATUS.NOT_STARTED).length
    };
  }, [dashboardControls]);

  return (
    <Grid gap='4' data-testid='action-cards' columns='repeat(auto-fill, minmax(333px, 1fr))'>
      <ActionCard variant='quickWins' total={filteredData.totalQuickWins} data-testid='quick-wins'>
        <ActionCardLink
          status='IN_PROGRESS'
          risk='HIGH'
          impact='LOW'
          actionLength={filteredData.numQuickWinsInProgress}
          type={type}
        />

        <ActionCardLink
          status='NOT_STARTED'
          risk='HIGH'
          impact='LOW'
          actionLength={filteredData.numQuickWinsNotStarted}
          type={type}
        />
      </ActionCard>

      <ActionCard variant='highRisk' total={filteredData.totalHighRisk} data-testid='high-risk'>
        <ActionCardLink
          status='IN_PROGRESS'
          risk='HIGH'
          actionLength={filteredData.numHighRiskInProgress}
          type={type}
        />

        <ActionCardLink
          status='NOT_STARTED'
          risk='HIGH'
          actionLength={filteredData.numHighRiskNotStarted}
          type={type}
        />
      </ActionCard>
      <ActionCard
        variant='incomplete'
        total={filteredData.totalIncomplete}
        data-testid='incomplete'
      >
        <ActionCardLink
          status='IN_PROGRESS'
          actionLength={filteredData.numIncompleteInProgress}
          type={type}
        />

        <ActionCardLink
          status='NOT_STARTED'
          actionLength={filteredData.numIncompleteNotStarted}
          type={type}
        />
      </ActionCard>
    </Grid>
  );
};
