import { FunctionComponent, useEffect, useMemo } from 'react';
import { matchPath, Navigate, Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import { NotFoundPage, UnauthorisedPage } from 'app/components';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import { PRODUCT_TYPES } from 'app/constants/app';
import {
  CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES,
  CUSTOMER_MANAGEMENT_REQUIRED_ROLES,
  MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES,
  USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES,
  USER_MANAGEMENT_REQUIRED_ROLES
} from 'app/constants/rbac';
import { URLS } from 'app/constants/routes';
import { ControlsProvider } from 'app/hooks/useControls';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';

import {
  AzureCreateMasterControl,
  AzureMasterControls,
  AzureUpdateMasterControl
} from './AdminCentre/AzureMasterControls';
import { CreateCustomer, Customers, UpdateCustomer } from './AdminCentre/Customers';
import {
  M365CreateMasterControl,
  M365MasterControls,
  M365UpdateMasterControl
} from './AdminCentre/M365MasterControls';
import { CreateMasterControlBulletin } from './AdminCentre/MasterControls';
import { CreateUser, UpdateUser, Users } from './AdminCentre/Users';
import { AutomatedAssessmentAuthorised } from './AutomatedAssessment/Authorised';
import { ControlDetails } from './Controls/ControlDetails/ControlDetails';
import { Controls } from './Controls';
import { Dashboard } from './Dashboard';

const defaultDocumentTitle = 'Overwatch';

const ROUTES = [
  {
    path: URLS.M365_DASHBOARDS,
    element: <Dashboard type={PRODUCT_TYPES.M365} />,
    title: 'Overwatch - M365 Dashboards'
  },
  {
    path: URLS.AZURE_DASHBOARDS,
    element: <Dashboard type={PRODUCT_TYPES.AZURE} />,
    title: 'Overwatch - Azure Dashboards'
  },
  {
    path: URLS.M365_CONTROLS,
    element: (
      <ControlsProvider type={PRODUCT_TYPES.M365}>
        <Controls type={PRODUCT_TYPES.M365} />
      </ControlsProvider>
    ),
    title: 'Overwatch - M365 Controls'
  },
  {
    path: URLS.AZURE_CONTROLS,
    element: (
      <ControlsProvider type={PRODUCT_TYPES.AZURE}>
        <Controls type={PRODUCT_TYPES.AZURE} />
      </ControlsProvider>
    ),
    title: 'Overwatch - Azure Controls'
  },
  {
    path: URLS.M365_CONTROL_DETAILS,
    element: (
      <ControlsProvider type={PRODUCT_TYPES.M365}>
        <ControlDetails type={PRODUCT_TYPES.M365} />
      </ControlsProvider>
    ),
    title: 'Overwatch - M365 Control Details'
  },
  {
    path: URLS.AZURE_CONTROL_DETAILS,
    element: (
      <ControlsProvider type={PRODUCT_TYPES.AZURE}>
        <ControlDetails type={PRODUCT_TYPES.AZURE} />
      </ControlsProvider>
    ),
    title: 'Overwatch - Azure Control Details'
  },
  {
    path: URLS.ADMIN_CENTRE_CUSTOMERS,
    element: <Customers />,
    title: 'Overwatch - Customer Management - Customers',
    restrictions: CUSTOMER_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CUSTOMERS_CREATE,
    element: <CreateCustomer />,
    title: 'Overwatch - Customer Management - New Customer',
    restrictions: CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_CUSTOMERS_EDIT,
    element: <UpdateCustomer />,
    title: 'Overwatch - Customer Management - Edit Customer',
    restrictions: CUSTOMER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_USERS,
    element: <Users />,
    title: 'Overwatch - User Management - Users',
    restrictions: USER_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_USERS_CREATE,
    element: <CreateUser />,
    title: 'Overwatch - User Management - New User',
    restrictions: USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_USERS_EDIT,
    element: <UpdateUser />,
    title: 'Overwatch - User Management - Edit User',
    restrictions: USER_MANAGEMENT_ADD_MODIFY_DELETE_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_M365_CONTROLS,
    element: <M365MasterControls />,
    title: 'Overwatch - M365 Control Management - Master Controls',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_M365_CONTROLS_CREATE,
    element: <M365CreateMasterControl />,
    title: 'Overwatch - M365 Control Management - New Control',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_M365_CONTROLS_EDIT,
    element: <M365UpdateMasterControl />,
    title: 'Overwatch - M365 Control Management - Edit Control',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_M365_CONTROLS_BULLETIN_CREATE,
    element: <CreateMasterControlBulletin section={ADMIN_SECTIONS.M365_CONTROLS} />,
    title: 'Overwatch - M365 Control Management - New Bulletin',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_M365_CONTROLS_BULLETIN_EDIT,
    element: <CreateMasterControlBulletin section={ADMIN_SECTIONS.M365_CONTROLS} />,
    title: 'Overwatch - M365 Control Management - New Bulletin',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_AZURE_CONTROLS,
    element: <AzureMasterControls />,
    title: 'Overwatch - Azure Control Management - Master Controls',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_AZURE_CONTROLS_CREATE,
    element: <AzureCreateMasterControl />,
    title: 'Overwatch - Azure Control Management - New Control',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_AZURE_CONTROLS_EDIT,
    element: <AzureUpdateMasterControl />,
    title: 'Overwatch - Azure Control Management - Edit Control',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_AZURE_CONTROLS_BULLETIN_CREATE,
    element: <CreateMasterControlBulletin section={ADMIN_SECTIONS.AZURE_CONTROLS} />,
    title: 'Overwatch - Azure Control Management - New Bulletin',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.ADMIN_CENTRE_AZURE_CONTROLS_BULLETIN_EDIT,
    element: <CreateMasterControlBulletin section={ADMIN_SECTIONS.AZURE_CONTROLS} />,
    title: 'Overwatch - Azure Control Management - New Bulletin',
    restrictions: MASTER_CONTROLS_MANAGEMENT_REQUIRED_ROLES
  },
  {
    path: URLS.AUTOMATED_ASSESSMENT_AUTHORISED,
    element: <AutomatedAssessmentAuthorised />,
    title: 'Overwatch - Automated Assessment Authorisation'
  }
];

const Routes: FunctionComponent = () => {
  const location = useLocation();
  const { currentProduct, isAllowed } = useLoggedInUser();
  useEffect(() => {
    const currentRoute = ROUTES.find(route => matchPath(route.path, location.pathname));

    document.title = currentRoute ? currentRoute.title : defaultDocumentTitle;
  }, [location]);

  const DEFAULT_DASHBOARDS_URL = useMemo(
    () => (currentProduct === PRODUCT_TYPES.M365 ? URLS.M365_DASHBOARDS : URLS.AZURE_DASHBOARDS),
    [currentProduct]
  );
  const DEFAULT_CONTROLS_URL = useMemo(
    () => (currentProduct === PRODUCT_TYPES.M365 ? URLS.M365_CONTROLS : URLS.AZURE_CONTROLS),
    [currentProduct]
  );

  return (
    <ReactRoutes>
      <Route path='/'>
        {ROUTES.map(route => {
          return !route.restrictions || isAllowed(route.restrictions) ? (
            <Route key={route.path} path={route.path} element={route.element} />
          ) : (
            <Route key={route.path} path={route.path} element={<UnauthorisedPage />} />
          );
        })}
        <Route
          path={URLS.DASHBOARDS}
          element={<Navigate to={`/${DEFAULT_DASHBOARDS_URL}`} replace={true} />}
        />
        <Route
          path={URLS.CONTROLS}
          element={<Navigate to={`/${DEFAULT_CONTROLS_URL}`} replace={true} />}
        />
        <Route index element={<Navigate to={DEFAULT_DASHBOARDS_URL} replace={true} />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </ReactRoutes>
  );
};

export default Routes;
