import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { M365_NIS_PRINCIPLES_API_URLS } from 'app/constants/routes';
import { NISPrinciple, WrappedError } from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

export const useFetchNISPrinciples = (enabled = false) =>
  useQuery<NISPrinciple[], WrappedError>({
    queryKey: ['useFetchNISPrinciples'],
    queryFn: () =>
      axiosInstance
        .get<NISPrinciple[]>(M365_NIS_PRINCIPLES_API_URLS.ROOT, {})
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
