import { FunctionComponent, useMemo } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { CONTROL_CHECKS_IS_COMPLIANT } from 'app/constants/controls';
import { CustomerControlCheck } from 'app/types';
import { getOverallComplianceLabel } from 'app/utils/control-utils';

import { ControlCheck } from './ControlCheck';
import { ControlCheckStatusIcon } from './ControlCheckStatusIcons';

const useStyles = tss.withName('ControlChecksTab').create(() => ({
  container: {
    color: 'var(--font-primary-color)',
    padding: '8px 0 0'
  },
  overallCompliance: {
    fontSize: '20px',
    lineHeight: 1.4,
    letterSpacing: '-0.01px',
    fontWeight: 700
  },
  overallComplianceText: {
    fontSize: '14px',
    lineHeight: 1.71,
    color: 'var(--font-primary-color)'
  }
}));

type ControlChecksTabProps = {
  controlChecksData: CustomerControlCheck[];
};

export const ControlChecksTab: FunctionComponent<ControlChecksTabProps> = ({
  controlChecksData
}) => {
  const { classes } = useStyles();

  const [status, label] = useMemo(() => {
    const overallCompliance = controlChecksData.flatMap(check => check.isCompliant);
    const overallComplianceStatus = overallCompliance.every(
      check => check === CONTROL_CHECKS_IS_COMPLIANT.NOT_APPLICABLE
    )
      ? CONTROL_CHECKS_IS_COMPLIANT.NOT_APPLICABLE
      : overallCompliance.some(check => check === CONTROL_CHECKS_IS_COMPLIANT.NOT_COMPLIANT)
        ? CONTROL_CHECKS_IS_COMPLIANT.NOT_COMPLIANT
        : CONTROL_CHECKS_IS_COMPLIANT.COMPLIANT;

    return [overallComplianceStatus, getOverallComplianceLabel(overallComplianceStatus)];
  }, [controlChecksData]);

  return (
    <Flex className={classes.container} direction='column' gap='4' data-testid='control-checks-tab'>
      <Flex gap='2' align='center' justify='between' data-testid='overall-compliance'>
        <Text className={classes.overallCompliance}>Overall compliance:</Text>
        <Flex gap='2'>
          <Text className={classes.overallComplianceText}>{label}</Text>
          <ControlCheckStatusIcon status={status} />
        </Flex>
      </Flex>
      <Flex direction='column' gap='4'>
        {controlChecksData.map((controlCheck, index) => (
          <ControlCheck key={index} controlCheck={controlCheck} index={index} />
        ))}
      </Flex>
    </Flex>
  );
};
