import { FunctionComponent, useState } from 'react';
import { Card, Flex, Separator } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { AzureDashboardControl, M365DashboardControl, RangeType } from 'app/types/dashboard';

import { ProgressReportChart } from './ProgressReportChart';
import { ProgressReportInfo } from './ProgressReportInfo';

const useStyles = tss.withName('ProgressReport').create(() => ({
  container: {
    borderRadius: '6px',
    border: '1px solid var(--slate-200)',
    background: 'var(--white)',
    padding: '16px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    width: '100%',
    color: 'var(--font-primary-color)',

    '&:after': {
      boxShadow: 'none'
    }
  },
  separator: {
    flexBasis: '1px',
    background: 'var(--slate-200)',
    height: 'auto'
  }
}));

type ProgressReportProps = {
  dashboardControls: Array<M365DashboardControl | AzureDashboardControl>;
};

export const ProgressReport: FunctionComponent<ProgressReportProps> = ({ dashboardControls }) => {
  const { classes } = useStyles();

  const [selectedRange, setSelectedRange] = useState<RangeType>('1m');

  return (
    <Card className={classes.container} data-testid='progress-report'>
      <Flex gap='4'>
        <ProgressReportInfo
          dashboardControls={dashboardControls}
          onRangeChange={(value: RangeType) => setSelectedRange(value)}
          defaultValue={selectedRange}
        />
        <Separator orientation='vertical' className={classes.separator} />
        <ProgressReportChart dashboardControls={dashboardControls} selectedRange={selectedRange} />
      </Flex>
    </Card>
  );
};
