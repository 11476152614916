import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { AZURE_BENCHMARKS_API_URLS } from 'app/constants/routes';
import { Benchmark, WrappedError } from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

export const useFetchBenchmarks = (enabled = false) =>
  useQuery<Benchmark[], WrappedError>({
    queryKey: ['useFetchBenchmarks'],
    queryFn: () =>
      axiosInstance
        .get<Benchmark[]>(AZURE_BENCHMARKS_API_URLS.ROOT, {})
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
