import { FunctionComponent, useMemo } from 'react';
import { ChatText } from '@phosphor-icons/react';
import { Box, Tabs } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PRODUCT_TYPES } from 'app/constants/app';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useFetchCustomerControlChecks as useAzureFetchCustomerControlChecks } from 'app/queries/useAzureControlQueries';
import { useFetchCustomerControlChecks as useM365FetchCustomerControlChecks } from 'app/queries/useM365ControlQueries';

import { ControlChecksTab } from './ControlChecksTab/ControlChecksTab';
import { ControlPostureTab } from './ControlPostureTab';
import { ControlDetailsCommonProps } from './types';

const useStyles = tss.withName('ControlDetailsTabs').create(() => ({
  list: {
    backgroundColor: 'var(--white)',
    border: '1px solid var(--slate-200)',
    padding: '5px',
    borderRadius: '6px',
    boxShadow: 'none',
    gap: '5px'
  },
  trigger: {
    padding: '6px 12px',
    borderRadius: '6px',
    flex: 0,
    color: 'var(--font-primary-color)',
    cursor: 'pointer',
    height: '32px',

    '& > span': {
      fontSize: '14px',
      backgroundColor: 'transparent',
      display: 'flex',
      gap: '10px',
      fontWeight: 700,
      padding: '0',
      letterSpacing: '0'
    },

    '&::before': {
      content: 'none'
    },

    '&:focus, &[data-state=active]': {
      backgroundColor: 'var(--slate-200)'
    },

    '&:hover': {
      backgroundColor: 'var(--gray-a3)'
    }
  },
  tabsContainer: {
    backgroundColor: 'var(--white)',
    padding: '16px 8px 16px 16px',
    marginTop: '16px',
    borderRadius: '6px',
    border: '1px solid var(--slate-200)'
  }
}));

export const ControlDetailsTabs: FunctionComponent<ControlDetailsCommonProps> = ({
  control,
  updateControl,
  type
}) => {
  const { classes } = useStyles();
  const { isThreatscapeUser } = useLoggedInUser();

  const useFetchCustomerControlChecks = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return useAzureFetchCustomerControlChecks(control.externalControlId);
      case PRODUCT_TYPES.M365:
        return useM365FetchCustomerControlChecks(control.externalControlId);
    }
  };

  const { data: controlChecksData } = useFetchCustomerControlChecks();

  const shouldShowControlChecks = useMemo(
    () => isThreatscapeUser && controlChecksData && controlChecksData.length > 0,
    [controlChecksData, isThreatscapeUser]
  );

  return (
    <Tabs.Root defaultValue='notes'>
      <Tabs.List className={classes.list}>
        <Tabs.Trigger value='notes' className={classes.trigger} data-testid='notes-tab'>
          <ChatText width='16' height='16' weight='fill' role='none' />
          Notes
        </Tabs.Trigger>
        {shouldShowControlChecks && (
          <Tabs.Trigger
            value='control-checks'
            className={classes.trigger}
            data-testid='control-checks-tab'
          >
            Control checks
          </Tabs.Trigger>
        )}
      </Tabs.List>

      <Box className={classes.tabsContainer}>
        <Tabs.Content value='notes' tabIndex={-1}>
          <ControlPostureTab control={control} updateControl={updateControl} type={type} />
        </Tabs.Content>
        {shouldShowControlChecks && (
          <Tabs.Content value='control-checks' tabIndex={-1}>
            <ControlChecksTab controlChecksData={controlChecksData || []} />
          </Tabs.Content>
        )}
      </Box>
    </Tabs.Root>
  );
};
