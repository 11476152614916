import { FunctionComponent } from 'react';
import { Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { FreeTextFilter } from 'app/components/Filters';
import { NAVBAR_HEIGHT, PRODUCT_TYPES } from 'app/constants/app';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { useControlsContext } from 'app/hooks/useControls';

import { AzureFilterPanelItems } from './AzureFilterPanelItems';
import { M365FilterPanelItems } from './M365FilterPanelItems';
import { SortSelect } from './SortSelect';

const useStyles = tss.withName('FilterPanel').create(() => ({
  container: {
    background: 'var(--white)',
    padding: '8px 8px 16px',
    borderRadius: 'var(--radius-4)',
    border: '1px solid var(--slate-200)',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    overflow: 'auto',
    // height is: height of screen - navbar height - padding from top and bottom of page wrapper
    maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px - 32px)`,

    '& > *': {
      borderBottom: '1px solid var(--slate-200)',
      padding: '0 0 16px'
    },
    '& > *:last-child': {
      borderBottom: 'none',
      padding: '0 8px'
    }
  },
  filterContainer: {
    '& > *': {
      borderBottom: '1px solid var(--slate-200)',
      padding: '16px 0'
    },
    '& > *:last-child': {
      borderBottom: 'none',
      padding: '16px 0 0'
    }
  },
  freetextFilter: {
    backgroundColor: 'var(--bg-inputs)'
  }
}));

type FilterPanelProps = {
  onFilterChange: (prop: FILTER_PROP_NAMES, value?: string | string[]) => void;
  onSortChange: (newSort: string) => void;
  type: PRODUCT_TYPES;
};

export const FilterPanel: FunctionComponent<FilterPanelProps> = ({
  onFilterChange,
  onSortChange,
  type
}) => {
  const { classes } = useStyles();
  const { filters, sort } = useControlsContext();

  return (
    <Flex direction='column' className={classes.container} data-testid='filters-panel'>
      <Flex direction='column' gap='2'>
        <FreeTextFilter
          defaultValue={filters.freeText}
          className={classes.freetextFilter}
          onChange={(value: string) => onFilterChange(FILTER_PROP_NAMES.FREETEXT, value)}
        />
        <SortSelect defaultValue={sort} onChange={onSortChange} />
      </Flex>
      <Flex direction='column' className={classes.filterContainer}>
        {type === PRODUCT_TYPES.AZURE && <AzureFilterPanelItems onFilterChange={onFilterChange} />}
        {type === PRODUCT_TYPES.M365 && <M365FilterPanelItems onFilterChange={onFilterChange} />}
      </Flex>
    </Flex>
  );
};
