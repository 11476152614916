import { FunctionComponent, useMemo, useState } from 'react';
import { Button, Flex } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { ControlCheckData, CustomerControlCheck } from 'app/types';

import { ComplexTableGroupingRow } from './ComplexTableGroupingRow';

const useStyles = tss.withName('ControlCheckTypeComplexTable').create(() => ({
  tableRowHeader: {
    borderBottom: '1px solid var(--slate-200)',
    color: 'var(--font-secondary-color)',
    '&:last-of-type': {
      borderBottom: 'none'
    },
    '&>th:last-of-type': {
      paddingRight: '0'
    }
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: '8px',
    borderCollapse: 'collapse'
  },
  tableHeaderCell: {
    fontSize: '12px',
    lineHeight: 1.67,
    fontWeight: 700,
    color: 'var(--font-secondary-color)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    textAlign: 'left',
    paddingRight: '8px'
  },
  tableRow: {
    borderBottom: '1px solid var(--slate-200)',
    '&>td:last-of-type': {
      paddingRight: '0'
    }
  },
  expandButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    color: 'var(--font-link-icon-color)',
    textDecoration: 'underline'
  },
  expandButtonCell: {
    width: '30px'
  }
}));

type ControlCheckTypeComplexTableProps = {
  controlCheck: CustomerControlCheck;
};

export const ControlCheckTypeComplexTable: FunctionComponent<ControlCheckTypeComplexTableProps> = ({
  controlCheck
}) => {
  const { classes } = useStyles();

  const [expandedItems, setExpandedItems] = useState<boolean[]>([]);

  const [groups, numGroups] = useMemo(() => {
    // group the data based on first property
    const groupedControlCheckByFirstPropertyValue = controlCheck.checkData?.reduce(
      (acc, item) => {
        const firstProperty = Object.values(item)[0] as string;
        acc[firstProperty] = acc[firstProperty] || [];
        acc[firstProperty]!.push(item);
        return acc;
      },
      {} as Record<string, ControlCheckData[]>
    );

    // get the number of groups
    const numGroups = groupedControlCheckByFirstPropertyValue
      ? Object.values(groupedControlCheckByFirstPropertyValue).length
      : 0;

    // update expanded items state
    setExpandedItems(numGroups ? Array(numGroups).fill(false) : []);

    return [groupedControlCheckByFirstPropertyValue, numGroups];
  }, [controlCheck.checkData]);

  const allRowsExpanded = expandedItems.every(item => item);

  const toggleAllRows = () => {
    const newState = !allRowsExpanded;
    setExpandedItems(Array(numGroups).fill(newState));
  };

  const toggleItemRow = (index: number) => {
    const newItems = [...expandedItems];
    newItems[index] = !newItems[index];
    setExpandedItems(newItems);
  };

  return (
    <Flex justify='between' gap='1' direction='column'>
      <Button
        variant='ghost'
        size='1'
        color='gray'
        className={classes.expandButton}
        onClick={() => toggleAllRows()}
        data-testid='complex-table-expand-all-button'
      >
        {allRowsExpanded ? 'Collapse all' : 'Expand all'}
      </Button>
      <table className={classes.table}>
        <thead>
          <tr className={classes.tableRowHeader}>
            <th className={classes.expandButtonCell} />
            {controlCheck.properties.map((property, index) => (
              <th
                key={`${property.name}-${index}`}
                className={classes.tableHeaderCell}
                data-testid={`complex-table-header-cell-${index}`}
              >
                {property.displayName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groups &&
            Object.values(groups).map((controlCheckDataItem, index) => (
              <ComplexTableGroupingRow
                controlCheckDataItem={controlCheckDataItem}
                index={index}
                controlCheckProperties={controlCheck.properties}
                key={`complex-table-row-${index}`}
                toggleItemRow={() => toggleItemRow(index)}
                expandedItems={expandedItems}
              />
            ))}
        </tbody>
      </table>
    </Flex>
  );
};
