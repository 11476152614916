import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { AZURE_MASTER_CONTROLS_API_URLS } from 'app/constants/routes';
import {
  AzureMasterControl,
  AzureMasterControlRequest,
  PagedResponse,
  UseFetchMasterControlsOptions,
  WrappedError
} from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

export const USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY = 'useFetchMasterControlsList';

export const useFetchMasterControlsList = ({
  page = 0,
  size = 20,
  sort = 'name,desc',
  freeText = '',
  enabled = true
}: UseFetchMasterControlsOptions) => {
  return useQuery<PagedResponse<AzureMasterControl>, WrappedError>({
    queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY, { page, size, sort, freeText }],
    queryFn: async () =>
      await axiosInstance
        .get<PagedResponse<AzureMasterControl>>(AZURE_MASTER_CONTROLS_API_URLS.ROOT, {
          params: { page, size, sort, freeText }
        })
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
};

const USE_FETCH_MASTER_CONTROL_QUERY_KEY = 'useFetchMasterControl';
export const useFetchMasterControl = (enabled = false, id: string) =>
  useQuery<AzureMasterControl, WrappedError>({
    queryKey: [USE_FETCH_MASTER_CONTROL_QUERY_KEY, id],
    queryFn: async () =>
      await axiosInstance
        .get<AzureMasterControl>(AZURE_MASTER_CONTROLS_API_URLS.SINGLE.replace(':id', id))
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });

interface CreateMasterControlOptions {
  control: AzureMasterControlRequest;
}
export const useCreateMasterControlMutation = () =>
  useMutation<AzureMasterControl, WrappedError, CreateMasterControlOptions>({
    mutationKey: ['useCreateMasterControlMutation'],
    mutationFn: async ({ control }: CreateMasterControlOptions) =>
      await axiosInstance
        .post<AzureMasterControl>(AZURE_MASTER_CONTROLS_API_URLS.ROOT, {
          ...control,
          name: control.name.trim(),
          controlNotes: control.controlNotes.trim()
        })
        .then(response => response.data)
        .catch(catchApiError)
  });

export interface UpdateMasterControlMutationOptions {
  id: string;
  control: AzureMasterControlRequest;
}
export const useUpdateMasterControlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<AzureMasterControl, WrappedError, UpdateMasterControlMutationOptions>({
    mutationKey: ['useUpdateMasterControlMutation'],
    mutationFn: async ({ id, control }: UpdateMasterControlMutationOptions) =>
      await axiosInstance
        .put<AzureMasterControl>(AZURE_MASTER_CONTROLS_API_URLS.SINGLE.replace(':id', `${id}`), {
          ...control,
          name: control.name.trim(),
          controlNotes: control.controlNotes.trim()
        })
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY, USE_FETCH_MASTER_CONTROL_QUERY_KEY]
      })
  });
};

export const useDeleteMasterControlMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['useDeleteMasterControlMutation'],
    mutationFn: async (externalControlId: string) =>
      await axiosInstance
        .delete(AZURE_MASTER_CONTROLS_API_URLS.SINGLE.replace(':id', `${externalControlId}`))
        .catch(catchApiError),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_MASTER_CONTROLS_LIST_QUERY_KEY] });
    }
  });
};
