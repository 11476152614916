import { FunctionComponent } from 'react';
import { Box, Flex, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

const useStyles = tss.withName('ControlDetailsSection').create(() => ({
  container: {
    background: 'var(--white)',
    paddingBottom: '16px',
    borderBottom: '1px solid var(--slate-200)',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700
  }
}));

type DetailsSectionProps = {
  label: string;
  children?: React.ReactNode;
};

export const DetailsSection: FunctionComponent<DetailsSectionProps> = ({ label, children }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.container}>
      <Flex gap='4' direction='column'>
        <Text as='p' className={classes.heading}>
          {label}
        </Text>
        <Box>{children}</Box>
      </Flex>
    </Box>
  );
};
