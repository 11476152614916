import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flex } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import { isThisMonth } from 'date-fns';

import {
  ConnectionError,
  PageLoadingIndicator,
  PageWrapper,
  SwitchProduct,
  UnauthorisedPage
} from 'app/components';
import { PRODUCT_TYPES } from 'app/constants/app';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  USE_FETCH_AZURE_DASHBOARD_CONTROLS_QUERY_KEY,
  USE_FETCH_M365_DASHBOARD_CONTROLS_QUERY_KEY,
  useFetchAzureDashboardControls,
  useFetchM365DashboardControls
} from 'app/queries/useDashboardControlsQueries';
import { AzureDashboardControl, CommonFilters, M365DashboardControl } from 'app/types';
import {
  buildFiltersFromSearchParams,
  filterDashboardControls,
  updateSearchParamsWithFilter
} from 'app/utils/control-utils';

import { ActionCards } from './ActionCards/ActionCards';
import { ControlsReport } from './ControlsReport/ControlsReport';
import { FilterPanel } from './FilterPanel/FilterPanel';
import { ProgressReport } from './ProgressReport/ProgressReport';
import { SolutionCards } from './SolutionCards/SolutionCards';
import { sortByStatus } from './dashboard.utils';

type DashboardProps = { type: PRODUCT_TYPES };

export const Dashboard: FunctionComponent<DashboardProps> = ({ type }) => {
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();

  // allows us to delay the query until we are ready (i.e. we have any filters from searchparams)
  const [ready, setReady] = useState(false);
  // get any filters from urlsearchparams
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState<CommonFilters>({});

  const useFetchDashboardControls = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return useFetchAzureDashboardControls(ready);
      case PRODUCT_TYPES.M365:
        return useFetchM365DashboardControls(ready);
    }
  };

  const { data, isFetching, error } = useFetchDashboardControls();

  const handleFilterChange = (prop: FILTER_PROP_NAMES, value?: string | string[]) => {
    // this will also trigger the useEffect below which calls setFilters
    setSearchParams(updateSearchParamsWithFilter(searchParams, prop, value));
  };

  const dataWithCompletedThisMonthStatus = useMemo(() => {
    if (isFetching) return data || [];
    if (!data) return [];

    const statusesCompletedThisMonth = (data as Array<M365DashboardControl | AzureDashboardControl>)
      .filter(({ status }) => status === DASHBOARD_STATUS.COMPLETED)
      .filter(({ completedAt }) => completedAt && isThisMonth(completedAt));

    return data
      .map(control => ({
        ...control,
        status: statusesCompletedThisMonth.includes(control)
          ? DASHBOARD_STATUS.COMPLETED_THIS_MONTH
          : control.status
      }))
      .sort(sortByStatus);
  }, [data, isFetching]);

  const filteredData = useMemo(
    () => filterDashboardControls(dataWithCompletedThisMonthStatus || [], filters, type),
    [dataWithCompletedThisMonthStatus, filters, type]
  );

  useEffect(() => {
    setFilters(buildFiltersFromSearchParams(searchParams, true));

    setReady(true);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [USE_FETCH_M365_DASHBOARD_CONTROLS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [USE_FETCH_AZURE_DASHBOARD_CONTROLS_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  const showUnauthError = useMemo(() => error?.status && error.status === 401, [error]);

  return (
    <PageWrapper withSidePadding={true}>
      <SwitchProduct />
      {showUnauthError ? (
        <UnauthorisedPage error={error} />
      ) : (
        <Flex gap='4' direction='column' height='fit-content' width='100%'>
          {isFetching && <PageLoadingIndicator />}
          {error && (
            <div data-testid='dashboard-error'>
              <ConnectionError />
            </div>
          )}
          {ready && !isFetching && data && (
            <>
              <ActionCards dashboardControls={data} type={type} />
              <ProgressReport dashboardControls={data} />
              <Flex gap='4' height='fit-content' width='100%'>
			  <FilterPanel filters={filters} onFilterChange={handleFilterChange} type={type} />

                <Flex gap='4' direction='column' height='fit-content' width='100%'>
				{filteredData && (
                  <>
                    <ControlsReport
                      dashboardControls={filteredData}
                      totalControls={data.length}
                      type={type}
                    />
                    <SolutionCards dashboardControls={filteredData} type={type} />
                  </>
                )}
                </Flex>
              </Flex>
            </>
          )}
        </Flex>
      )}
    </PageWrapper>
  );
};
