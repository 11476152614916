import { FunctionComponent } from 'react';
import { tss } from 'tss-react';

import { ControlDetailsCommonProps } from '../types';

import { ControlPosture } from './ControlPosture';
import { NotesList } from './NotesList';

const useStyles = tss.withName('ControlPostureTab').create(() => ({
  tabs: {
    maxHeight: '538px',
    overflow: 'auto',
    paddingRight: '8px'
  }
}));

export const ControlPostureTab: FunctionComponent<ControlDetailsCommonProps> = ({
  control,
  updateControl,
  type
}) => {
  const { classes } = useStyles();

  const handleNotesChange = () => {
    updateControl({
      ...control,
      lastUpdatedAt: new Date().toISOString()
    });
  };

  return (
    <div>
      <ControlPosture control={control} onControlChange={updateControl} type={type} />
      <div className={classes.tabs}>
        <NotesList
          controlId={control.externalControlId}
          onNotesChange={handleNotesChange}
          type={type}
        />
      </div>
    </div>
  );
};
