import { FunctionComponent } from 'react';

import { useFetchBenchmarks } from 'app/queries/useBenchmarksQueries';
import { CommonFilterProps } from 'app/types/filters';

import { FilterSection } from './common/FilterSection';
import { MultiSelectFilter } from './common/MultiSelectFilter';

export const BenchmarksFilter: FunctionComponent<CommonFilterProps> = ({
  defaultValue,
  onChange
}) => {
  const { data, isLoading } = useFetchBenchmarks(true);

  return (
    <FilterSection label='Benchmarks' labelId='benchmarks-filter-label' disableCollapse={true}>
      <MultiSelectFilter
        inputId='benchmarks-filter'
        items={(data || []).map(val => ({
          value: `${val.id}`,
          label: val.name
        }))}
        name='benchmarkIds'
        aria-labelledby='benchmarks-filter-label'
        isDisabled={isLoading}
        onChange={onChange}
        emptyText='All Benchmarks'
        data-testid='benchmarks-filter-select'
        defaultValue={
          data
            ?.filter(val => (defaultValue || []).includes(`${val.id}`))
            .map(benchmark => ({
              value: `${benchmark.id}`,
              label: benchmark.name
            })) ?? []
        }
      />
    </FilterSection>
  );
};
