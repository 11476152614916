import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { AZURE_SOLUTIONS_API_URLS, M365_SOLUTIONS_API_URLS } from 'app/constants/routes';
import { Solution, WrappedError } from 'app/types';
import { catchApiError } from 'app/utils/error-utils';

export const useFetchM365Solutions = (enabled = false) =>
  useQuery<Solution[], WrappedError>({
    queryKey: ['useFetchM365Solutions'],
    queryFn: () =>
      axiosInstance
        .get<Solution[]>(M365_SOLUTIONS_API_URLS.ROOT, {})
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });

export const useFetchAzureSolutions = (enabled = false) =>
  useQuery<Solution[], WrappedError>({
    queryKey: ['useFetchAzureSolutions'],
    queryFn: () =>
      axiosInstance
        .get<Solution[]>(AZURE_SOLUTIONS_API_URLS.ROOT, {})
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
