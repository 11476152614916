import { FunctionComponent } from 'react';

import {
  BenchmarksFilter,
  DashboardStatusFilter,
  ImpactFilter,
  InitialFindingsFilter,
  RiskFilter,
  SolutionsFilter
} from 'app/components/Filters';
import { ControlTypeFilter } from 'app/components/Filters/ControlTypeFilter';
import { PRODUCT_TYPES } from 'app/constants/app';
import { FILTER_PROP_NAMES } from 'app/constants/filters';
import { CommonFilters } from 'app/types';

type AzureFilterPanelItemsProps = {
  filters: CommonFilters;
  onFilterChange: (prop: FILTER_PROP_NAMES, value?: string | string[]) => void;
};

export const AzureFilterPanelItems: FunctionComponent<AzureFilterPanelItemsProps> = ({
  filters,
  onFilterChange
}) => {
  return (
    <>
      <DashboardStatusFilter
        defaultValue={filters.statuses}
        defaultAsOpen={!!filters.statuses}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.STATUSES, value)}
      />
      <RiskFilter
        defaultValue={filters.risk}
        defaultAsOpen={!!filters.risk}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.RISK, value)}
      />
      <ImpactFilter
        defaultValue={filters.impact}
        defaultAsOpen={!!filters.impact}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.IMPACT, value)}
      />
      <InitialFindingsFilter
        defaultValue={filters.initialFindings}
        defaultAsOpen={!!filters.initialFindings}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.INITIAL_FINDINGS, value)}
      />
      <ControlTypeFilter
        defaultValue={filters.controlTypes}
        defaultAsOpen={!!filters.controlTypes}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.CONTROL_TYPES, value)}
      />
      <SolutionsFilter
        defaultValue={filters.solutionIds}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.SOLUTIONS, value)}
        productType={PRODUCT_TYPES.AZURE}
      />
      <BenchmarksFilter
        defaultValue={filters.benchmarkIds}
        onChange={(value: string[]) => onFilterChange(FILTER_PROP_NAMES.BENCHMARKS, value)}
      />
    </>
  );
};
