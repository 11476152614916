export const NAVBAR_HEIGHT = 90;

export const LOCALSTORAGE_CUSTOMER_ID_KEY = 'overwatch-selected-customerId';

export enum PRODUCT_TYPES {
  AZURE = 'AZURE',
  M365 = 'M365'
}

export enum PRODUCT_TYPES_LABELS {
  AZURE = 'Azure',
  M365 = 'M365'
}

export const PRODUCT_TYPES_OPTIONS = [
  { label: PRODUCT_TYPES_LABELS.AZURE, value: PRODUCT_TYPES.AZURE },
  { label: PRODUCT_TYPES_LABELS.M365, value: PRODUCT_TYPES.M365 }
];
