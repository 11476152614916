import { FunctionComponent } from 'react';
import { Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { CONTROL_CHECKS_PROPERTIES_TYPES } from 'app/constants/controls';
import { ControlCheckDataValueType } from 'app/types';

import { ControlCheckItemArray } from './ControlCheckItemArray';

const useStyles = tss.withName('ControlCheckPropertyType').create(() => ({
  formItemValue: {
    fontSize: '12px',
    lineHeight: 1.67,
    color: 'var(--font-secondary-color)'
  }
}));

type ControlCheckTypeProps = {
  propertyType: CONTROL_CHECKS_PROPERTIES_TYPES;
  value: ControlCheckDataValueType;
  displayItems?: number;
};

export const ControlCheckPropertyType: FunctionComponent<ControlCheckTypeProps> = ({
  propertyType,
  value,
  displayItems
}) => {
  const { classes } = useStyles();

  switch (propertyType) {
    case CONTROL_CHECKS_PROPERTIES_TYPES.NUMBER:
    case CONTROL_CHECKS_PROPERTIES_TYPES.STRING:
      return <Text className={classes.formItemValue}>{value}</Text>;
    case CONTROL_CHECKS_PROPERTIES_TYPES.BOOLEAN:
      return <Text className={classes.formItemValue}>{value ? 'True' : 'False'}</Text>;
    case CONTROL_CHECKS_PROPERTIES_TYPES.NUMBER_ARRAY:
    case CONTROL_CHECKS_PROPERTIES_TYPES.STRING_ARRAY:
      return Array.isArray(value) ? (
        <ControlCheckItemArray value={value as string[] | number[]} displayItems={displayItems} />
      ) : null;
  }
};
