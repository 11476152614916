import { useEffect, useMemo, useState } from 'react';
import { Pencil, Warning } from '@phosphor-icons/react';
import { Box, Callout, Flex, IconButton, Spinner, Text, TextArea } from '@radix-ui/themes';
import { UseMutationResult } from '@tanstack/react-query';
import clsx from 'clsx';
import { format } from 'date-fns';
import { tss } from 'tss-react';

import { PrimaryButton, SecondaryButton } from 'app/components/Buttons';
import { UserAvatar } from 'app/components/User';
import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_POSTURE_REQUIRED_ROLES } from 'app/constants/rbac';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useControlPostureMutation as useAzureControlPostureMutation } from 'app/queries/useAzureControlQueries';
import { useControlPostureMutation as useM365ControlPostureMutation } from 'app/queries/useM365ControlQueries';
import { ApiError, ControlPostureMutationOptions } from 'app/types';
import { AzureCustomerControl } from 'app/types/azureControls';
import { M365CustomerControl } from 'app/types/m365Controls';

const useStyles = tss.withName('ControlPosture').create(() => ({
  container: {
    background: 'var(--white)',
    paddingBottom: '16px',
    borderBottom: '1px solid var(--slate-200)',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  heading: {
    color: 'var(--slate-900)',
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 700
  },
  button: {
    cursor: 'pointer'
  },
  readonly: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.7,
    fontWeight: 400,
    padding: '0 8px',
    whiteSpace: 'pre-wrap'
  },
  details: {
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: '700',
    justifyContent: 'space-between'
  },
  date: {
    color: 'var(--font-secondary-color)',
    fontWeight: 'normal',
    textAlign: 'right'
  },
  isAllowedEdit: {
    cursor: 'text',

    '&:hover, &:focus': {
      backgroundColor: 'var(--bg-default)'
    }
  },
  placeholder: {
    color: 'var(--font-secondary-color)',
    fontStyle: 'italic'
  },
  textarea: {
    minHeight: '74px',
    boxShadow: 'none',
    backgroundColor: 'var(--bg-inputs)',

    '&>textarea': {
      padding: '8px 12px',
      border: '1px solid var(--input-border-color)'
    }
  }
}));

type ControlPostureProps<ControlType extends AzureCustomerControl | M365CustomerControl> = {
  control: ControlType;
  onControlChange: (control: ControlType) => void;
  type: PRODUCT_TYPES;
};

export const ControlPosture = <ControlType extends AzureCustomerControl | M365CustomerControl>({
  control,
  onControlChange,
  type
}: ControlPostureProps<ControlType>) => {
  const { classes } = useStyles();
  const { isAllowed } = useLoggedInUser();

  const [newValue, setNewValue] = useState(control.controlPosture?.content);
  const [editMode, setEditMode] = useState(false);

  const useControlPostureMutation = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return useAzureControlPostureMutation();
      case PRODUCT_TYPES.M365:
        return useM365ControlPostureMutation();
    }
  };

  const { mutate, isPending, isError, reset } = useControlPostureMutation();

  const userIsAllowed = useMemo(() => isAllowed(CONTROL_POSTURE_REQUIRED_ROLES), [isAllowed]);

  const handleClick = () => {
    if (userIsAllowed) {
      setEditMode(true);
    }
  };

  const handleCancel = () => {
    reset();
    setNewValue(control.controlPosture?.content);
    setEditMode(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const mutation = mutate as UseMutationResult<
      ControlType,
      Error | ApiError,
      ControlPostureMutationOptions
    >['mutate'];

    mutation(
      { externalControlId: control.externalControlId, controlPosture: newValue || '' },
      {
        onSuccess: (response: ControlType) => {
          control.controlPosture = response.controlPosture;
          onControlChange(response);
          setEditMode(false);
        }
      }
    );
  };

  useEffect(() => setNewValue(control.controlPosture?.content), [control.controlPosture]);

  return (
    <Box className={classes.container}>
      <Flex gap='2' direction='column'>
        <Flex gap='2' align='center'>
          <Text as='p' className={classes.heading}>
            Control posture
          </Text>
          {!editMode && (
            <IconButton
              color='gray'
              variant='ghost'
              className={classes.button}
              onClick={handleClick}
              title='Edit control posture'
            >
              <Pencil size={16} role='none' />
            </IconButton>
          )}
        </Flex>
        {!editMode && (
          <>
            <div
              className={clsx(classes.readonly, userIsAllowed && classes.isAllowedEdit)}
              onClick={handleClick}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleClick();
                }
              }}
              data-testid='readonly-view'
              tabIndex={0}
              role='button'
            >
              {!newValue && (
                <span className={classes.placeholder}>
                  No initial findings.
                  <br />
                  Click here to start typing.
                </span>
              )}
              {newValue && <span>{newValue}</span>}
            </div>
            <Flex direction='row' gap='4' data-testid='note'>
              <div>
                <UserAvatar user={control.controlPosture?.user} />
              </div>
              <Flex direction='column' justify='center' flexGrow='1'>
                <Flex className={classes.details} direction='row' gap='2'>
                  <Text as='span'>{control.controlPosture?.user?.name || 'Deleted user'}</Text>
                  <Text as='span' className={classes.date}>
                    {control.controlPosture?.lastUpdatedAt
                      ? format(control.controlPosture?.lastUpdatedAt, 'dd MMMM yyyy')
                      : ''}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
        {editMode && userIsAllowed && (
          <Box>
            <form onSubmit={handleSubmit} data-testid='editmode-view'>
              <Flex gap='2' direction='column'>
                <TextArea
                  className={classes.textarea}
                  resize='vertical'
                  color='gray'
                  autoFocus
                  placeholder='No control posture.'
                  disabled={isPending}
                  value={newValue}
                  onChange={e => setNewValue(e.target.value)}
                  data-testid='controlPosture-input'
                  aria-label='Control posture'
                />
                {isError && (
                  <Callout.Root color='red' size='1' data-testid='controlPosture-error'>
                    <Callout.Icon>
                      <Warning role='none' />
                    </Callout.Icon>
                    <Callout.Text>Unable to save data.</Callout.Text>
                  </Callout.Root>
                )}
                <Flex gap='4' direction='row' justify='end' align='center'>
                  {isPending && <Spinner />}
                  <SecondaryButton
                    type='reset'
                    onClick={handleCancel}
                    disabled={isPending}
                    data-testid='cancel-btn'
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton type='submit' disabled={isPending} data-testid='submit-btn'>
                    Save
                  </PrimaryButton>
                </Flex>
              </Flex>
            </form>
          </Box>
        )}
      </Flex>
    </Box>
  );
};
