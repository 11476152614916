import { FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, Flex, Grid, Text } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { SolutionIcon } from 'app/components';
import { DashboardStatusIcon } from 'app/components/Dashboard';
import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_URLS_BY_PRODUCT } from 'app/constants/controls';
import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { AzureDashboardControl, M365DashboardControl } from 'app/types';
import { buildSearchParamsFromFilters } from 'app/utils/control-utils';

import { groupBySolution, isCompletedThisMonth } from '../dashboard.utils';
import { DashboardStatusTooltip } from '../DashboardStatusTooltip';

const useStyles = tss.withName('SolutionCards').create(() => ({
  card: {
    border: '1px solid var(--slate-200)',
    background: 'var(--white)',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    '&:after': {
      boxShadow: 'none'
    }
  },
  link: {
    color: 'var(--font-primary-color)',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline'
    }
  },
  title: {
    color: 'var(--font-primary-color)',
    fontSize: '14px',
    lineHeight: 1.43
  },
  count: {
    color: 'var(--font-secondary-color)',
    fontSize: '12px',
    lineHeight: 1.667,
    flex: 1,
    alignItems: 'flex-end',
    display: 'flex'
  },
  solutionIcon: {
    flexShrink: 0
  },
  statusIconLink: {
    display: 'flex'
  }
}));

type SolutionCardsProps = {
  dashboardControls: Array<M365DashboardControl | AzureDashboardControl>;
  type: PRODUCT_TYPES;
};

export const SolutionCards: FunctionComponent<SolutionCardsProps> = ({
  dashboardControls,
  type
}) => {
  const { classes } = useStyles();

  const solutionGroups = useMemo(() => groupBySolution(dashboardControls), [dashboardControls]);

  return (
    <Grid
      gap='4'
      columns={`repeat(auto-fit, minmax(246px, ${solutionGroups.length > 2 ? '1' : '0.334'}fr))`}
      data-testid='solutions'
    >
      {solutionGroups.map(solutionGroup => (
        <Card className={classes.card} key={solutionGroup.solution?.id}>
          <Link
            to={`/${CONTROL_URLS_BY_PRODUCT[type].controlListLink}?${buildSearchParamsFromFilters({ solutionIds: [solutionGroup.solution?.id.toString()] })}`}
            className={classes.link}
            data-testid='solution-card-link'
          >
            <Text className={classes.title}>
              <Flex gap={'2'} align={'start'}>
                <SolutionIcon solution={solutionGroup.solution} className={classes.solutionIcon} />
                {solutionGroup.solution?.name}
              </Flex>
            </Text>
          </Link>
          <Flex gap='1' wrap={'wrap'}>
            {solutionGroup.controls.map((control, index) => (
              <DashboardStatusTooltip control={control} key={index}>
                <Link
                  to={`/${CONTROL_URLS_BY_PRODUCT[type].controlDetailsLink.replace(':id', control.externalControlId)}`}
                  className={classes.statusIconLink}
                  data-testid='solutions-tooltip-link'
                >
                  <DashboardStatusIcon status={isCompletedThisMonth(control)} size={24} />
                </Link>
              </DashboardStatusTooltip>
            ))}
          </Flex>
          <Text className={classes.count}>
            {
              solutionGroup.controls.filter(
                control => control.status === DASHBOARD_STATUS.COMPLETED || control.status === DASHBOARD_STATUS.COMPLETED_THIS_MONTH
              ).length
            }
            {' of '}
            {solutionGroup.controls.length} completed
          </Text>
        </Card>
      ))}
    </Grid>
  );
};
