import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  ArrowLeft,
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight
} from '@phosphor-icons/react';
import { Flex, IconButton } from '@radix-ui/themes';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { tss } from 'tss-react';

import { EmptyResult, PageLoadingIndicator, PageWrapper, UnauthorisedPage } from 'app/components';
import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_URLS_BY_PRODUCT } from 'app/constants/controls';
import { useControlsContext } from 'app/hooks/useControls';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY as AZURE_QUERY_KEY,
  useFetchCustomerControl as useFetchAzureCustomerControl
} from 'app/queries/useAzureControlQueries';
import {
  USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY as M365_QUERY_KEY,
  useFetchCustomerControl as useFetchM365CustomerControl
} from 'app/queries/useM365ControlQueries';
import { M365CustomerControl } from 'app/types';
import { AzureCustomerControl } from 'app/types/azureControls';
import { createFiltersLink } from 'app/utils/control-utils';

import { ControlDetailsPanel } from './ControlDetailsPanel';
import { ControlDetailsTabs } from './ControlDetailsTabs';

const useStyles = tss.withName('ControlDetails').create(() => ({
  mainContainer: {
    margin: '0 auto',
    flex: '1 1 auto'
  },
  container: {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  details: {
    background: 'var(--bg-default)',
    borderColor: 'var(--slate-200)',
    padding: '16px',
    boxShadow: '0px 4px 4px 0px var(--box-shadow-color)',
    minWidth: '349px'
  },
  button: {
    padding: '8px 16px',
    borderRadius: '6px',
    background: 'var(--white)',
    color: 'var(--font-primary-color)',
    border: '1px solid var(--slate-200)',
    fontSize: '14px',
    lineHeight: 1.42,
    fontWeight: 400,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '&:hover, &:focus': {
      background: 'var(--slate-200)'
    }
  },
  backButton: {
    flex: '1'
  },
  loadingContainer: {
    background: 'var(--white)',
    border: '1px solid var(--slate-200)',
    padding: '16px',
    height: 'auto',
    width: '1080px',
    minHeight: '538px'
  },
  expandButton: {
    marginTop: '112px',
    borderRadius: '100%',
    borderTop: '1px solid var(--slate-200)',
    borderRight: '1px solid var(--slate-200)',
    borderBottom: '1px solid var(--slate-200)',
    background: 'var(--white)',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    color: 'var(--font-primary-color)',
    cursor: 'pointer'
  },
  fullWidth: {
    flex: '1 !important'
  },
  tabsContainer: {
    flex: '0 1 715px',
    maxWidth: '100%'
  }
}));

type ControlDetailsProps = { type: PRODUCT_TYPES };

export const ControlDetails: FunctionComponent<ControlDetailsProps> = ({ type }) => {
  const { classes } = useStyles();
  const [controlData, setControlData] = useState<M365CustomerControl | AzureCustomerControl>();
  const [isExpanded, setIsExpanded] = useState(false);

  const params = useParams();
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();
  const { isThreatscapeUser } = useLoggedInUser();

  const isFetchEnabled = !!params.id && (isThreatscapeUser ? !!selectedCustomer?.id : true);

  const useFetchCustomerControl = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return useFetchAzureCustomerControl(isFetchEnabled, params.id || '');
      case PRODUCT_TYPES.M365:
        return useFetchM365CustomerControl(isFetchEnabled, params.id || '');
    }
  };

  const {
    filteredData,
    filters,
    sort,
    refetchControls,
    isFetching: isFetchingControls
  } = useControlsContext();

  const { data, isFetching: isFetchingDetails, error } = useFetchCustomerControl();

  const filteredDataIds = useMemo(
    () => filteredData?.map(({ externalControlId }) => externalControlId) ?? [],
    [filteredData]
  );

  const currentControlIndex = filteredDataIds.indexOf(params.id || '');
  const previousControl = currentControlIndex > 0 ? filteredDataIds[currentControlIndex - 1] : null;
  const nextControl =
    currentControlIndex >= 0 && currentControlIndex < filteredDataIds.length - 1
      ? filteredDataIds[currentControlIndex + 1]
      : null;

  const updateControlData = (updatedControl: M365CustomerControl | AzureCustomerControl) => {
    setControlData(updatedControl);
    refetchControls();
  };

  useEffect(() => {
    setControlData(data);
  }, [data, isFetchingDetails]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [M365_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [AZURE_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  const showUnauthError = useMemo(() => error?.status && error.status === 401, [error]);

  return showUnauthError ? (
    <UnauthorisedPage error={error} />
  ) : (
    <PageWrapper>
      <Flex
        className={clsx(classes.mainContainer, isExpanded && classes.fullWidth)}
        gap='4'
        justify='center'
      >
        <Flex direction='column' gap='4' flexGrow='0' flexBasis={isExpanded ? '100%' : '1080px'}>
          <Flex justify='between' gap='4'>
            <Link
              to={`/${CONTROL_URLS_BY_PRODUCT[type].controlListLink}?controlId=${params.id}${createFiltersLink(filters, sort) && `&${createFiltersLink(filters, sort)}`}`}
              className={clsx(classes.button, classes.backButton)}
            >
              <ArrowLeft size={16} weight='bold' role='none' />
              Back to control list
            </Link>
            {!isFetchingControls && (
              <>
                {previousControl && !error && (
                  <Link
                    to={`/${CONTROL_URLS_BY_PRODUCT[type].controlDetailsLink.replace(':id', `${previousControl}`)}`}
                    className={classes.button}
                  >
                    <CaretLeft size={16} weight='bold' role='none' />
                    <span>Previous</span> #{previousControl}
                  </Link>
                )}
                {nextControl && !error && (
                  <Link
                    to={`/${CONTROL_URLS_BY_PRODUCT[type].controlDetailsLink.replace(':id', `${nextControl}`)}`}
                    className={classes.button}
                  >
                    <span>Next</span> #{nextControl}
                    <CaretRight size={16} weight='bold' role='none' />
                  </Link>
                )}
              </>
            )}
          </Flex>
          {isFetchingDetails && (
            <div className={classes.loadingContainer}>
              <PageLoadingIndicator />
            </div>
          )}
          {!isFetchingDetails && (
            <div className={classes.container}>
              {error ? (
                <EmptyResult
                  title='Control not found'
                  content='Please go back and select a control from the list.'
                />
              ) : (
                controlData && (
                  <>
                    <ControlDetailsPanel
                      control={controlData}
                      updateControl={updateControlData}
                      type={type}
                    />
                    <div className={clsx(classes.tabsContainer, isExpanded && classes.fullWidth)}>
                      <ControlDetailsTabs
                        control={controlData}
                        updateControl={updateControlData}
                        type={type}
                      />
                    </div>
                  </>
                )
              )}
            </div>
          )}
        </Flex>
        {controlData && !error && !isFetchingDetails && (
          <IconButton
            className={classes.expandButton}
            data-testid='expand-button'
            aria-label={`${isExpanded ? 'Contract' : 'Expand'} control details`}
            title={`${isExpanded ? 'Contract' : 'Expand'} control details`}
            variant='ghost'
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <CaretDoubleLeft size={16} role='none' />
            ) : (
              <CaretDoubleRight size={16} role='none' />
            )}
          </IconButton>
        )}
      </Flex>
    </PageWrapper>
  );
};
