import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CaretDown, MagnifyingGlass, UserSwitch } from '@phosphor-icons/react';
import { Button, IconButton, Popover, Text, TextField } from '@radix-ui/themes';
import clsx from 'clsx';
import { Command as CommandPrimitive } from 'cmdk';
import { tss } from 'tss-react';

import { URLS, URLS_BY_PRODUCT_TYPE } from 'app/constants/routes';
import useComponentVisible from 'app/hooks/useComponentVisible';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import { SimpleCustomer } from 'app/types';

import { SwitchCustomerItem } from './SwitchCustomerItem';

const useStyles = tss
  .withName('SwitchCustomer')
  .withParams<{ locked?: boolean }>()
  .create(({ locked }) => ({
    container: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center'
    },
    lockButton: {
      padding: '2px',
      borderRadius: '999px',
      backgroundColor: locked ? 'transparent' : 'var(--slate-700)',
      color: locked ? 'var(--slate-700)' : 'var(--white)',

      ':where(.rt-variant-ghost)': {
        margin: 0
      }
    },
    plaintext: {
      cursor: 'not-allowed',
      color: 'var(--font-primary-color)',
      fontSize: '14px',
      lineHeight: 1.5,
      fontWeight: 400,
      padding: '8px 12px',
      width: '175px'
    },
    button: {
      boxShadow: 'none',
      border: '1px solid var(--input-border-color)',
      backgroundColor: 'var(--bg-inputs)',
      cursor: 'pointer',
      color: 'var(--font-primary-color)',
      padding: '8px 12px',
      fontSize: '14px',
      lineHeight: 1.5,
      fontWeight: 400,
      height: '40px',
      width: '200px',
      justifyContent: 'space-between',
      gap: '10px',

      '& > span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },

      '&:hover': {
        backgroundColor: 'var(--gray-a3)'
      }
    },
    popoverContent: {
      width: '200px',
      padding: 0,
      background: 'transparent',
      overflow: 'visible',
      boxShadow: 'none'
    },
    command: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px'
    },
    input: {
      backgroundColor: 'var(--white)',
      height: '40px',
      boxShadow: 'var(--shadow-5)',
      '& > input': {
        border: '1px solid var(--input-border-color)',
        borderLeft: 'none'
      }
    },
    list: {
      maxHeight: '270px',
      overflow: 'auto',
      backgroundColor: 'var(--white)',
      padding: '4px',
      borderRadius: '6px',
      border: '1px solid var(--slate-200)',
      borderTop: 'none',
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
      boxShadow: 'var(--shadow-5)'
    },
    item: {
      borderRadius: '6px',
      padding: '6px 8px',
      display: 'flex',
      gap: '8px',
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 400,
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'space-between',

      '&:hover, &[data-selected="true"]': {
        backgroundColor: 'var(--gray-a3)'
      },
      '&[data-selected="true"]': {
        outline: '2px solid var(--focus-8)',

        '&:hover': {
          outline: 'none'
        }
      },
      '& > svg': {
        opacity: 0
      }
    },
    selected: {
      backgroundColor: 'var(--bg-default)',

      '& > svg': {
        opacity: 1
      }
    },
    groupHeading: {
      padding: '6px 8px',
      display: 'flex',
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 700,
      borderTop: '1px solid var(--slate-200)'
    },
    empty: {
      padding: '6px 8px',
      fontSize: '14px',
      lineHeight: 1.4,
      fontWeight: 400
    },
    textfieldIcon: {
      border: '1px solid var(--input-border-color)',
      borderRight: 'none',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px'
    }
  }));

export const SwitchCustomer: FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const { currentProduct } = useLoggedInUser();

  const [locked, setLocked] = useState(true);
  const [open, setOpen] = useState(false);

  const { classes } = useStyles({ locked });

  const { isLoading, customers, selectedCustomer, setSelectedCustomer } = useSelectedCustomer();

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(!locked);
  const {
    ref: commandRef,
    isComponentVisible: isCommandComponentVisible,
    setIsComponentVisible: setIsCommandComponentVisible
  } = useComponentVisible(!locked);

  const handleLockChange = () => {
    setLocked(!locked);
    setIsComponentVisible(locked);
    setIsCommandComponentVisible(locked);
  };

  const onSelectCustomer = (customer: SimpleCustomer) => {
    setSelectedCustomer(customer);
    setOpen(false);

    searchParams.delete('controlId');
    setSearchParams(searchParams);

    setLocked(true);
    setIsComponentVisible(false);
    setIsCommandComponentVisible(false);

    // Redirect the user if the current product is not supported by the selected customer
    if (
      (pathname.includes(URLS.DASHBOARDS) || pathname.includes(URLS.CONTROLS)) &&
      !(currentProduct && customer.products.includes(currentProduct))
    ) {
      const { DASHBOARD_URL, CONTROLS_URL } = URLS_BY_PRODUCT_TYPE[customer.products[0]];
      const URL_TO_USE = pathname.includes(URLS.DASHBOARDS) ? DASHBOARD_URL : CONTROLS_URL;

      navigate(`/${URL_TO_USE}`);
    }
  };

  const [active, inactive] = useMemo(
    () => [
      (customers || []).filter(val => val.enabled).sort((a, b) => a.name.localeCompare(b.name)),
      (customers || []).filter(val => !val.enabled).sort((a, b) => a.name.localeCompare(b.name))
    ],
    [customers]
  );

  useEffect(() => {
    // IF dropdown is showing && it's not locked && dropdown is not open - then lock
    (!isComponentVisible || !isCommandComponentVisible) && !locked && !open && setLocked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible, isCommandComponentVisible]);

  // if the customers haven't been loaded yet, don't render anything
  return isLoading ? (
    <></>
  ) : (
    <div className={classes.container} ref={ref}>
      <IconButton
        className={classes.lockButton}
        color='gray'
        data-testid={'switch-customer-lock'}
        onClick={handleLockChange}
        title={`${locked ? 'Unlock' : 'Lock'} Switch Customer`}
      >
        <UserSwitch size={24} role='none' />
      </IconButton>

      {locked ? (
        <div className={classes.plaintext} data-testid={'switch-customer-readonly'}>
          {selectedCustomer ? selectedCustomer.name : 'Select customer'}
        </div>
      ) : (
        <Popover.Root open={open} onOpenChange={setOpen} defaultOpen={true}>
          <Popover.Trigger>
            <Button
              variant='outline'
              color='gray'
              role={open ? 'combobox' : undefined}
              aria-expanded={open}
              aria-label='Switch Customer dropdown'
              className={classes.button}
              data-testid='switch-customer-btn'
              id='switch-customer-btn'
              aria-controls={open ? 'switch-customer-content' : undefined}
            >
              <span>{selectedCustomer ? selectedCustomer.name : 'Select customer'}</span>
              <CaretDown role='none' />
            </Button>
          </Popover.Trigger>
          <Popover.Content
            className={classes.popoverContent}
            data-testid='switch-customer-content'
            id='switch-customer-content'
            role='listbox'
          >
            <CommandPrimitive
              className={classes.command}
              filter={(value, search) =>
                value.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ? 1 : 0
              }
              ref={commandRef}
            >
              <CommandPrimitive.Input
                className={classes.input}
                asChild
                placeholder='Search...'
                aria-label='Search'
                aria-autocomplete='none'
                aria-hidden={!open}
                hidden={!open}
              >
                <TextField.Root>
                  <TextField.Slot className={classes.textfieldIcon}>
                    <MagnifyingGlass size={16} aria-hidden={true} />
                  </TextField.Slot>
                </TextField.Root>
              </CommandPrimitive.Input>
              <CommandPrimitive.List className={classes.list}>
                <CommandPrimitive.Empty className={classes.empty}>
                  No customers found.
                </CommandPrimitive.Empty>
                <CommandPrimitive.Group>
                  {active.map(customer => (
                    <SwitchCustomerItem
                      key={customer.id}
                      customer={customer}
                      classNames={clsx(
                        classes.item,
                        selectedCustomer?.id === customer.id && classes.selected
                      )}
                      onSelect={() => onSelectCustomer(customer)}
                    />
                  ))}
                </CommandPrimitive.Group>
                {inactive.length > 0 && (
                  <CommandPrimitive.Group
                    heading={<Text className={classes.groupHeading}>Inactive</Text>}
                  >
                    {inactive.map(customer => (
                      <SwitchCustomerItem
                        key={customer.id}
                        customer={customer}
                        classNames={clsx(
                          classes.item,
                          selectedCustomer?.id === customer.id && classes.selected
                        )}
                        onSelect={() => onSelectCustomer(customer)}
                      />
                    ))}
                  </CommandPrimitive.Group>
                )}
              </CommandPrimitive.List>
            </CommandPrimitive>
          </Popover.Content>
        </Popover.Root>
      )}
    </div>
  );
};
