import { FunctionComponent } from 'react';

import { ADMIN_SECTIONS } from 'app/constants/admin';
import { PRODUCT_TYPES } from 'app/constants/app';
import { useFetchMasterControlsList } from 'app/queries/useM365MasterControlsQueries';

import { MasterControls } from '../MasterControls';

export const M365MasterControls: FunctionComponent = () => {
  return (
    <MasterControls
      type={PRODUCT_TYPES.M365}
      section={ADMIN_SECTIONS.M365_CONTROLS}
      useFetchMasterControlsList={useFetchMasterControlsList}
    />
  );
};
