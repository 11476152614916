import { FunctionComponent } from 'react';
import { CheckboxGroup } from '@radix-ui/themes';

import { AZURE_CONTROL_TYPE_OPTIONS } from 'app/constants/controls';
import { useCommonFilterStyles } from 'app/constants/filters';
import { CommonFilterProps } from 'app/types/filters';

import { CheckboxFilter } from './common/CheckboxFilter';

export const ControlTypeFilter: FunctionComponent<CommonFilterProps> = props => {
  const { classes } = useCommonFilterStyles();

  return (
    <CheckboxFilter
      label='Control type'
      labelId='control-type-filter'
      {...props}
      options={AZURE_CONTROL_TYPE_OPTIONS.map(({ label, value }) => (
        <CheckboxGroup.Item
          key={`control-type-${value}`}
          value={value}
          className={classes.checkboxItem}
          data-testid={`control-type-filter-item-${value}`}
        >
          {label}
        </CheckboxGroup.Item>
      ))}
    />
  );
};
