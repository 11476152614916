import { FunctionComponent } from 'react';

import { ADMIN_SECTIONS } from 'app/constants/admin';
import { PRODUCT_TYPES } from 'app/constants/app';
import { useFetchMasterControlsList } from 'app/queries/useAzureMasterControlsQueries';

import { MasterControls } from '../MasterControls';

export const AzureMasterControls: FunctionComponent = () => {
  return (
    <MasterControls
      type={PRODUCT_TYPES.AZURE}
      section={ADMIN_SECTIONS.AZURE_CONTROLS}
      useFetchMasterControlsList={useFetchMasterControlsList}
    />
  );
};
