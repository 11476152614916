import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CaretDoubleLeft, CaretDoubleRight } from '@phosphor-icons/react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Button } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { PRODUCT_TYPES, PRODUCT_TYPES_LABELS } from 'app/constants/app';
import { URLS } from 'app/constants/routes';
import useComponentVisible from 'app/hooks/useComponentVisible';
import { useLoggedInUser } from 'app/hooks/useLoggedInUser';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import { ReactComponent as AzureLogo } from 'assets/azure-logo.svg';
import { ReactComponent as M365Logo } from 'assets/m365-logo.svg';

const useStyles = tss
  .withName('SwitchProduct')
  .withParams<{ open?: boolean }>()
  .create(({ open }) => ({
    container: {
      position: 'fixed',
      background: 'var(--white)',
      left: 0,
      zIndex: 99999,
      border: '1px solid var(--slate-200)',
      borderLeft: 'none',
      borderRadius: '0 6px 6px 0',
      padding: open ? '0 4px 4px' : '0'
    },
    trigger: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      width: '100%',
      background: 'var(--white)',
      borderRadius: '0 6px 6px 0',
      padding: open ? '4px 4px 8px' : '4px',
      gap: '8px',
      border: 'none',
      cursor: 'pointer'
    },
    selectedIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '60px',
      width: '24px',
      height: '24px',
      background: 'var(--product-selected-bg)',
      color: 'var(--white)'
    },

    content: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px'
    },
    option: {
      height: '24px',
      borderRadius: '6px',
      margin: 0,
      padding: '0 4px',
      gap: '4px',

      '&:where(.rt-variant-solid)': {
        backgroundColor: 'var(--product-selected-bg)'
      },
      '&:where(.rt-variant-ghost)': {
        color: 'var(--font-primary-color)',
        '> svg': {
          color: 'var(--product-selected-bg)'
        }
      },

      ':disabled': {
        opacity: 0.7
      }
    }
  }));

type ProductTypeOption = { name: string; value: PRODUCT_TYPES; icon: ReactNode; disabled: boolean };
const PRODUCT_OPTIONS: ProductTypeOption[] = [
  {
    name: PRODUCT_TYPES_LABELS.M365,
    value: PRODUCT_TYPES.M365,
    icon: <M365Logo />,
    disabled: false
  },
  {
    name: PRODUCT_TYPES_LABELS.AZURE,
    value: PRODUCT_TYPES.AZURE,
    icon: <AzureLogo />,
    disabled: false
  }
];

export const SwitchProduct: FunctionComponent = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, isThreatscapeUser, setCurrentProduct } = useLoggedInUser();
  const { selectedCustomer } = useSelectedCustomer();

  const [open, setOpen] = useState(false);
  const { classes } = useStyles({ open });

  const [selectedProduct, setSelectedProduct] = useState<ProductTypeOption>(PRODUCT_OPTIONS[0]);

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(open);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    setIsComponentVisible(open);
  };

  const handleClick = (val: ProductTypeOption) => {
    if (val.value === selectedProduct.value) {
      handleOpenChange(false);
      return;
    }

    setSelectedProduct(val);
    setCurrentProduct(val.value);
    handleOpenChange(false);

    const URL_TO_USE =
      pathname.includes(URLS.M365_DASHBOARDS) || pathname.includes(URLS.AZURE_DASHBOARDS)
        ? pathname.includes(URLS.M365_DASHBOARDS)
          ? URLS.AZURE_DASHBOARDS
          : URLS.M365_DASHBOARDS
        : pathname.includes(URLS.M365_CONTROLS)
          ? URLS.AZURE_CONTROLS
          : URLS.M365_CONTROLS;

    navigate(`/${URL_TO_USE}`);
  };

  useEffect(() => {
    !isComponentVisible && open && setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  useEffect(() => {
    PRODUCT_OPTIONS.map(
      val =>
        (val.disabled = !(
          (isThreatscapeUser ? selectedCustomer?.products : user?.customer?.products) || []
        ).includes(val.value))
    );
  }, [isThreatscapeUser, selectedCustomer, user]);

  useEffect(() => {
    const viewingProduct =
      pathname.includes(URLS.M365_DASHBOARDS) || pathname.includes(URLS.M365_CONTROLS)
        ? PRODUCT_OPTIONS[0] // M365
        : PRODUCT_OPTIONS[1]; // AZURE
    setCurrentProduct(viewingProduct.value);
    setSelectedProduct(viewingProduct);
  }, [pathname, setCurrentProduct]);

  return (
    <Collapsible.Root
      ref={ref}
      open={open}
      onOpenChange={handleOpenChange}
      className={classes.container}
    >
      <Collapsible.Trigger className={classes.trigger} data-testid='switch-product-trigger' title={`${ open ? 'Close' : 'Open'} switch product panel`}>
        {open ? (
          <CaretDoubleLeft size={16} role='none' />
        ) : (
          <>
            <CaretDoubleRight size={16} role='none' />
            <div className={classes.selectedIcon} data-testid='switch-product-selected-icon'>
              {selectedProduct.icon}
            </div>
          </>
        )}
      </Collapsible.Trigger>
      <Collapsible.Content className={classes.content}>
        {PRODUCT_OPTIONS.map(val => (
          <Button
            className={classes.option}
            variant={selectedProduct.value === val.value ? 'solid' : 'ghost'}
            key={val.value}
            aria-label={`Switch product to ${val.name}`}
            onClick={() => handleClick(val)}
            data-testid='switch-product-option'
            disabled={val.disabled}
          >
            {val.icon} {val.name}
          </Button>
        ))}
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
