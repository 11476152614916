import { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import * as FormPrimitive from '@radix-ui/react-form';
import { Callout, Flex } from '@radix-ui/themes';

import { SolutionIcon } from 'app/components';
import {
  FormField,
  FormInput,
  FormLabel,
  FormMessage,
  FormMultiSelect,
  FormPanel,
  FormRadioGroup,
  FormSelect,
  FormTextArea
} from 'app/components/Forms';
import { AdminCentrePageWrapper } from 'app/components/PageWrappers/AdminCentrePageWrapper';
import { ADMIN_SECTIONS } from 'app/constants/admin';
import {
  AZURE_CONTROL_TYPE,
  AZURE_CONTROL_TYPE_OPTIONS,
  RISK_OPTIONS
} from 'app/constants/controls';
import { RISK_VALUE } from 'app/constants/dashboard';
import { URLS } from 'app/constants/routes';
import { WHATS_NEW_STORED_MASTER_CONTROL_INFO } from 'app/constants/whatsNew';
import {
  useFetchMasterControl,
  useUpdateMasterControlMutation
} from 'app/queries/useAzureMasterControlsQueries';
import { useFetchBenchmarks } from 'app/queries/useBenchmarksQueries';
import { useFetchAzureSolutions } from 'app/queries/useSolutionQueries';
import { AzureMasterControlRequest } from 'app/types/azureControls';
import { stringifyAzureControlDataChanges } from 'app/utils/bulletin-utils';
import { getMessageFromError } from 'app/utils/error-utils';

export const AzureUpdateMasterControl: FunctionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: originalMasterControl, isFetching: isOriginalMasterControlLoading } =
    useFetchMasterControl(!!params.id, params.id || '');

  const [masterControl, setMasterControl] = useState<AzureMasterControlRequest | undefined>();

  const [showExternalControlIdError, setShowExternalControlIdError] = useState(false);
  const [showNameError, setShowNameError] = useState(false);
  const [showNotesError, setShowNotesError] = useState(false);
  const [showTypeError, setShowTypeError] = useState(false);
  const [showSecurityRiskError, setShowSecurityRiskError] = useState(false);
  const [showBusinessImpactError, setShowBusinessImpactError] = useState(false);
  const [showSolutionError, setShowSolutionError] = useState(false);
  const [showBenchmarksError, setShowBenchmarksError] = useState(false);

  const { data: solutionsData, isLoading: isSolutionsDataLoading } = useFetchAzureSolutions(true);
  const { data: benchmarkData, isLoading: isBenchmarksDataLoading } = useFetchBenchmarks(true);

  const { mutate, isPending, isError, error } = useUpdateMasterControlMutation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    // prevent default form submission
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const externalControlId = formData.get('externalControlId');
    const name = formData.get('name');
    const notes = formData.get('notes');
    const type = formData.get('type');
    const securityRisk = formData.get('securityRisk');
    const businessRisk = formData.get('businessRisk');
    const solutionId = formData.get('solutionId');
    const benchmarkIds = formData
      .getAll('benchmarkIds')
      .map(id => Number(id))
      .filter(id => id !== 0);

    const externalControlIdError = !externalControlId;
    const nameError = !name;
    const notesError = !notes;
    const typeError = !type;
    const securityRiskError = !securityRisk;
    const businessImpactError = !businessRisk;
    const solutionError = !solutionId;
    const benchmarkError = benchmarkIds.length === 0;

    setShowExternalControlIdError(externalControlIdError);
    setShowNameError(nameError);
    setShowNotesError(notesError);
    setShowTypeError(typeError);
    setShowSecurityRiskError(securityRiskError);
    setShowBusinessImpactError(businessImpactError);
    setShowSolutionError(solutionError);
    setShowBenchmarksError(benchmarkError);

    if (
      externalControlIdError ||
      nameError ||
      notesError ||
      typeError ||
      securityRiskError ||
      businessImpactError ||
      solutionError ||
      benchmarkError
    ) {
      return;
    }

    const updateControl = {
      externalControlId: (externalControlId as string).toLocaleUpperCase(),
      name: name as string,
      controlNotes: notes as string,
      securityRisk: securityRisk as RISK_VALUE,
      businessRisk: businessRisk as RISK_VALUE,
      solutionId: Number(solutionId),
      benchmarkIds: benchmarkIds,
      type: type as AZURE_CONTROL_TYPE
    };

    mutate(
      { id: params.id!, control: updateControl },
      {
        onSuccess: () => {
          navigate(
            `/${URLS.ADMIN_CENTRE_AZURE_CONTROLS_BULLETIN_EDIT.replace(':id', externalControlId as string)}`
          );
          localStorage.setItem(
            WHATS_NEW_STORED_MASTER_CONTROL_INFO,
            stringifyAzureControlDataChanges(masterControl!, updateControl, solutionsData!)
          );
        }
      }
    );
  };

  const handleCancel = () => {
    // redirect to list page
    navigate(`/${URLS.ADMIN_CENTRE_AZURE_CONTROLS}`);
    localStorage.removeItem(WHATS_NEW_STORED_MASTER_CONTROL_INFO);
  };

  useEffect(() => {
    if (!isOriginalMasterControlLoading && originalMasterControl) {
      setMasterControl({
        externalControlId: originalMasterControl.externalControlId,
        name: originalMasterControl.name,
        controlNotes: originalMasterControl.controlNotes || '',
        securityRisk: originalMasterControl.securityRisk?.toUpperCase() as RISK_VALUE,
        businessRisk: originalMasterControl.businessRisk?.toUpperCase() as RISK_VALUE,
        solutionId: originalMasterControl.solution?.id || 0,
        benchmarkIds: originalMasterControl.benchmarks?.map(benchmark => benchmark.id) || [],
        type: originalMasterControl.type || ''
      });
    }
  }, [isOriginalMasterControlLoading, originalMasterControl]);

  return (
    <AdminCentrePageWrapper section={ADMIN_SECTIONS.AZURE_CONTROLS}>
      <FormPanel
        heading='Edit Control'
        onSubmit={handleSubmit}
        onCancelClick={handleCancel}
        isSubmitting={isPending}
        isDisabled={!originalMasterControl}
      >
        {isError && (
          <Callout.Root color='red' size='1' data-testid='control-form-error'>
            <Callout.Icon>
              <Warning role='none' />
            </Callout.Icon>
            <Callout.Text>Unable to create control.</Callout.Text>
            {error && <Callout.Text>{getMessageFromError(error)}</Callout.Text>}
          </Callout.Root>
        )}
        {originalMasterControl && masterControl && (
          <Flex gap='6'>
            <Flex direction='column' gap='4' minWidth='200px'>
              <FormField name='externalControlId' data-testid='control-id-field'>
                <FormLabel>Control ID</FormLabel>
                <FormInput
                  name='externalControlId'
                  maxLength={7}
                  pattern='(a|A)\d{6,6}'
                  autoCapitalize='characters'
                  onChange={val => setShowExternalControlIdError(val.target.value.length === 0)}
                  onBlur={e => (e.target.value = e.target.value.toLocaleUpperCase())}
                  disabled={isPending}
                  defaultValue={masterControl.externalControlId}
                />
                <FormMessage match='valueMissing' forceMatch={showExternalControlIdError}>
                  This field is required
                </FormMessage>
                <FormMessage match='patternMismatch'>
                  Value must follow format &ldquo;A123456&rdquo;
                </FormMessage>
              </FormField>
              <FormField name='name' data-testid='control-name-field'>
                <FormLabel>Name</FormLabel>
                <FormInput
                  name='name'
                  onChange={val => setShowNameError(val.target.value.length === 0)}
                  disabled={isPending}
                  defaultValue={masterControl.name}
                />
                <FormMessage match='valueMissing' forceMatch={showNameError}>
                  This field is required
                </FormMessage>
              </FormField>
              <FormField name='notes' data-testid='control-notes-field'>
                <FormLabel>Control notes</FormLabel>
                <FormTextArea
                  name='notes'
                  onChange={val => setShowNotesError(val.target.value.length === 0)}
                  disabled={isPending}
                  defaultValue={masterControl.controlNotes}
                />
                <FormMessage match='valueMissing' forceMatch={showNotesError}>
                  This field is required
                </FormMessage>
              </FormField>
            </Flex>
            <Flex direction='column' gap='4' minWidth='200px'>
              <FormPrimitive.Field name='type' data-testid='control-type-field'>
                <FormLabel id='control-type-label' defaultLabel>
                  Control type
                </FormLabel>
                <FormRadioGroup
                  id='control-type'
                  aria-labelledby='control-type-label'
                  name='type'
                  disabled={isPending}
                  onInvalid={() => setShowTypeError(true)}
                  items={AZURE_CONTROL_TYPE_OPTIONS}
                  onValueChange={val => setShowTypeError(!val)}
                  defaultValue={masterControl.type}
                />
                <FormMessage match='valueMissing' forceMatch={showTypeError}>
                  This field is required
                </FormMessage>
              </FormPrimitive.Field>
              <FormPrimitive.Field name='securityRisk' data-testid='control-security-risk-field'>
                <FormLabel id='control-security-risk-label' defaultLabel>
                  Risk
                </FormLabel>
                <FormRadioGroup
                  id='control-security-risk'
                  aria-labelledby='control-security-risk-label'
                  name='securityRisk'
                  disabled={isPending}
                  onInvalid={() => setShowSecurityRiskError(true)}
                  items={RISK_OPTIONS}
                  onValueChange={val => setShowSecurityRiskError(!val)}
                  labelVariant='risk'
                  defaultValue={masterControl.securityRisk}
                />
                <FormMessage match='valueMissing' forceMatch={showSecurityRiskError}>
                  This field is required
                </FormMessage>
              </FormPrimitive.Field>
              <FormPrimitive.Field name='businessRisk' data-testid='control-business-risk-field'>
                <FormLabel id='control-business-risk-label' defaultLabel>
                  Impact
                </FormLabel>
                <FormRadioGroup
                  id='control-business-risk'
                  aria-labelledby='control-business-risk-label'
                  name='businessRisk'
                  disabled={isPending}
                  onInvalid={() => setShowBusinessImpactError(true)}
                  items={RISK_OPTIONS}
                  onValueChange={val => setShowBusinessImpactError(!val)}
                  labelVariant='risk'
                  defaultValue={masterControl.businessRisk}
                />
                <FormMessage match='valueMissing' forceMatch={showBusinessImpactError}>
                  This field is required
                </FormMessage>
              </FormPrimitive.Field>
            </Flex>
            <Flex direction='column' gap='4' minWidth='200px'>
              <FormPrimitive.Field name='solutionId' data-testid='control-solution-field'>
                <FormLabel id='control-solution-label' htmlFor='control-solution'>
                  Solution
                </FormLabel>
                <FormSelect
                  name='solutionId'
                  triggerProps={{
                    'aria-labelledby': 'control-solution-label',
                    'aria-controls': '',
                    'aria-autocomplete': 'list',
                    placeholder: 'None',
                    id: 'control-solution'
                  }}
                  items={(solutionsData || []).map(val => ({
                    value: `${val.id}`,
                    label: val.name,
                    icon: <SolutionIcon solution={val} />
                  }))}
                  disabled={isSolutionsDataLoading || isPending}
                  onValueChange={val => setShowSolutionError(val.length === 0)}
                  defaultValue={masterControl.solutionId.toString()}
                />
                <FormMessage match='valueMissing' forceMatch={showSolutionError}>
                  This field is required
                </FormMessage>
              </FormPrimitive.Field>
              <FormPrimitive.Field name='benchmarkIds' data-testid='control-benchmark-field'>
                <FormLabel id='control-benchmark-label' htmlFor='control-benchmark'>
                  Benchmarks
                </FormLabel>
                <FormMultiSelect
                  inputId='control-benchmark'
                  aria-labelledby='control-benchmark-label'
                  items={(benchmarkData || []).map(val => ({
                    value: `${val.id}`,
                    label: val.name
                  }))}
                  name='benchmarkIds'
                  isDisabled={isBenchmarksDataLoading || isPending}
                  onChange={() => setShowBenchmarksError(false)}
                  defaultValue={[
                    ...(originalMasterControl.benchmarks?.map(benchmark => ({
                      value: `${benchmark.id}`,
                      label: benchmark.name
                    })) ?? [])
                  ]}
                />
                <FormMessage match='valueMissing' forceMatch={showBenchmarksError}>
                  This field is required
                </FormMessage>
              </FormPrimitive.Field>
            </Flex>
          </Flex>
        )}
      </FormPanel>
    </AdminCentrePageWrapper>
  );
};
