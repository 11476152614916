import { FunctionComponent } from 'react';
import { Box, Flex, Select, Text, VisuallyHidden } from '@radix-ui/themes';
import { tss } from 'tss-react';

import { DASHBOARD_STATUS } from 'app/constants/dashboard';
import { AzureDashboardControl, M365DashboardControl, RangeType } from 'app/types/dashboard';

const useStyles = tss.withName('ProgressReportInfo').create(() => ({
  container: {
    color: 'var(--font-primary-color)',
    fontSize: '16px',
    fontWeight: 900,
    lineHeight: '1.06'
  },
  percentageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    fontWeight: 900
  },
  percentageValue: {
    fontSize: '41px',
    lineHeight: '29px',
    letterSpacing: '-4.1px',
    fontWeight: 400
  },
  percentageSymbol: {
    fontSize: '18px',
    lineHeight: '18px',
    marginLeft: '3px'
  },
  defaultTextHeight: {
    lineHeight: '1.06'
  },
  additionalInfo: {
    display: 'flex',
    fontWeight: 400
  },
  trigger: {
    padding: '8px 8px 8px 13px;',
    backgroundColor: 'var(--bg-inputs)',
    cursor: 'pointer',
    color: 'var(--font-primary-color)',
    height: '40px',
    borderRadius: '6px',
    border: '1px solid var(--input-border-color)',

    '&:hover': {
      backgroundColor: 'var(--accent-a3)'
    }
  }
}));

type ProgressReportInfoProps = {
  dashboardControls: Array<M365DashboardControl | AzureDashboardControl>;
  defaultValue: RangeType;
  onRangeChange: (value: RangeType) => void;
};

export const ProgressReportInfo: FunctionComponent<ProgressReportInfoProps> = ({
  dashboardControls,
  defaultValue,
  onRangeChange
}) => {
  const { classes } = useStyles();
  const noNoRequiredStatuses = dashboardControls.filter(
    progressReport => progressReport.status !== DASHBOARD_STATUS.NOT_REQUIRED
  );
  const completedStatuses = dashboardControls.filter(
    progressReport => progressReport.status === DASHBOARD_STATUS.COMPLETED
  );

  const completedStatussesPercentage = Math.round(
    (completedStatuses.length / noNoRequiredStatuses.length) * 100
  );

  const selectOptions = [
    { value: '1m', name: '1 month' },
    { value: '3m', name: '3 months' },
    { value: '6m', name: '6 months' },
    { value: '12m', name: '12 months' }
  ];

  return (
    <Box flexBasis={'225px'} className={classes.container}>
      <Flex direction='column' gap='4'>
        <Flex gap='5' direction={'column'}>
          <Text className={classes.percentageContainer} data-testid='progress-report-percentage'>
            <span>
              <span className={classes.percentageValue}>{completedStatussesPercentage}</span>
              <span className={classes.percentageSymbol}>%</span>
            </span>
            <span className={classes.defaultTextHeight}>complete</span>
          </Text>
          <Text className={classes.defaultTextHeight} data-testid='progress-report-controls-values'>
            {completedStatuses.length} of {noNoRequiredStatuses.length} controls
            <span className={classes.additionalInfo}>excluding &ldquo;not required&rdquo;</span>
          </Text>
        </Flex>
        <VisuallyHidden asChild>
          <label htmlFor='progress-report-range-select'>Select Date Range</label>
        </VisuallyHidden>
        <Select.Root size='2' defaultValue={defaultValue} onValueChange={onRangeChange}>
          <Select.Trigger
            color='gray'
            variant='soft'
            className={classes.trigger}
            id='progress-report-range-select'
            data-testid='progress-report-range-select'
            aria-controls=''
            aria-autocomplete='list'
          />
          <Select.Content color='gray' variant='soft' id='progress-report-range-content'>
            {selectOptions.map(option => (
              <Select.Item key={option.value} value={option.value}>
                {option.name}
              </Select.Item>
            ))}
          </Select.Content>
        </Select.Root>
      </Flex>
    </Box>
  );
};
