import { useQuery } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { USERS_API_URLS } from 'app/constants/routes';
import { WrappedError } from 'app/types/ApiError';
import { UserProfile } from 'app/types/users';
import { catchApiError } from 'app/utils/error-utils';

export const useFetchLoggedInUser = () =>
  useQuery<UserProfile, WrappedError>({
    queryKey: ['loggedInUser'],
    queryFn: () =>
      axiosInstance
        .get<UserProfile>(USERS_API_URLS.LOGGED_IN_USER)
        .then(response => response.data)
        .catch(catchApiError)
  });
