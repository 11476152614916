import { LICENCES, LICENCES_LABELS } from 'app/constants/customers';

import { PRODUCT_TYPES } from './app';
import { RISK_VALUE } from './dashboard';
import { URLS } from './routes';

export enum CONTROL_STATUS {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  NOT_REQUIRED = 'NOT_REQUIRED'
}

export enum CONTROL_STATUS_LABELS {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  COMPLETED = 'Completed',
  NOT_REQUIRED = 'Not required'
}

export enum CONTROL_CHECKS_DISPLAY_TYPES {
  FORM = 'FORM',
  TABLE = 'TABLE',
  COMPLEX_TABLE= 'COMPLEX_TABLE',
  PIE_CHART = 'PIE_CHART',
  BAR_CHART = 'BAR_CHART'
}

export enum CONTROL_CHECKS_PROPERTIES_TYPES {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  NUMBER_ARRAY = 'NUMBER_ARRAY',
  STRING_ARRAY = 'STRING_ARRAY',
  BOOLEAN = 'BOOLEAN'
}

export enum CONTROL_CHECKS_IS_COMPLIANT {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  COMPLIANT = 'COMPLIANT',
  NOT_COMPLIANT = 'NOT_COMPLIANT'
}

export type CONTROL_STATUS_KEYS = keyof typeof CONTROL_STATUS;

export const CONTROL_STATUS_VALUES = Object.values(CONTROL_STATUS);

export const CONTROL_SORT_OPTIONS: { name: string; value: string }[] = [
  { name: 'Control ID', value: 'externalControlId,asc' },
  { name: 'Highest risk', value: 'securityRisk,asc' },
  { name: 'Lowest risk', value: 'securityRisk,desc' },
  { name: 'Highest impact', value: 'businessRisk,asc' },
  { name: 'Lowest impact', value: 'businessRisk,desc' },
  { name: 'Last updated', value: 'lastUpdatedAt,desc' }
];

export type SortOrder = 'asc' | 'desc';
export type SortBy =
  | 'externalControlId'
  | 'name'
  | 'solution'
  | 'securityRisk'
  | 'businessRisk'
  | 'nisPrinciples'
  | 'azureType';

/**
 * Used in Create/Update Forms
 */
export const LICENCE_TYPE_OPTIONS = [
  { label: LICENCES_LABELS.E3, value: LICENCES.E3 },
  { label: LICENCES_LABELS.E5, value: LICENCES.E5 },
  { label: LICENCES_LABELS.BUSINESS_PREMIUM, value: LICENCES.BUSINESS_PREMIUM }
];

export const RISK_OPTIONS = [
  { label: RISK_VALUE.LOW, value: RISK_VALUE.LOW },
  { label: RISK_VALUE.MEDIUM, value: RISK_VALUE.MEDIUM },
  { label: RISK_VALUE.HIGH, value: RISK_VALUE.HIGH }
];

export enum AZURE_CONTROL_TYPE {
  TECHNICAL = 'TECHNICAL',
  PROCESS = 'PROCESS'
}
export enum AZURE_CONTROL_TYPE_LABELS {
  TECHNICAL = 'Technical',
  PROCESS = 'Process'
}

export const AZURE_CONTROL_TYPE_OPTIONS = [
  { label: AZURE_CONTROL_TYPE_LABELS.PROCESS, value: AZURE_CONTROL_TYPE.PROCESS },
  { label: AZURE_CONTROL_TYPE_LABELS.TECHNICAL, value: AZURE_CONTROL_TYPE.TECHNICAL }
];

export const CONTROL_URLS_BY_PRODUCT = {
  [PRODUCT_TYPES.AZURE]: {
    controlDetailsLink: URLS.AZURE_CONTROL_DETAILS,
    controlListLink: URLS.AZURE_CONTROLS
  },
  [PRODUCT_TYPES.M365]: {
    controlDetailsLink: URLS.M365_CONTROL_DETAILS,
    controlListLink: URLS.M365_CONTROLS
  }
};

export const MAX_NUMBER_OF_SHOWED_CONTROL_CHECKS_ARRAY_ITEMS = 5;
