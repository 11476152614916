import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { axiosInstance } from 'app/constants/axios';
import { AZURE_CONTROLS_API_URLS } from 'app/constants/routes';
import { useSelectedCustomer } from 'app/hooks/useSelectedCustomer';
import {
  ControlNote,
  ControlPostureMutationOptions,
  CustomerControlCheck,
  CustomerControlNotesMutationOptions,
  PagedResponse,
  StatusMutationOptions,
  WrappedError
} from 'app/types';
import { AzureCustomerControl } from 'app/types/azureControls';
import { catchApiError } from 'app/utils/error-utils';

export const USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY = 'useAzureFetchCustomerControls';
export const useFetchCustomerControls = (enabled = false) => {
  const { selectedCustomer } = useSelectedCustomer();
  return useQuery<AzureCustomerControl[], WrappedError>({
    queryKey: [USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY],
    queryFn: () =>
      axiosInstance
        .get<AzureCustomerControl[]>(AZURE_CONTROLS_API_URLS.ROOT, {
          params: { customerId: selectedCustomer?.id }
        })
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
};

export const USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY = 'useAzureFetchCustomerControl';
export const useFetchCustomerControl = (enabled = false, externalControlId: string) => {
  const { selectedCustomer } = useSelectedCustomer();

  return useQuery<AzureCustomerControl, WrappedError>({
    queryKey: [USE_FETCH_CUSTOMER_CONTROL_QUERY_KEY, externalControlId],
    queryFn: async () =>
      await axiosInstance
        .get<AzureCustomerControl>(
          AZURE_CONTROLS_API_URLS.SINGLE.replace(':controlId', externalControlId),
          {
            params: { customerId: selectedCustomer?.id }
          }
        )
        .then(response => response.data)
        .catch(catchApiError),
    enabled
  });
};

export const USE_FETCH_CUSTOMER_CONTROL_NOTES_QUERY_KEY = 'useAzureFetchCustomerControlNotes';
export const useFetchCustomerControlNotes = (enabled = false, externalControlId: string) => {
  const { selectedCustomer } = useSelectedCustomer();
  const options = {
    customerId: selectedCustomer?.id,
    size: 20,
    sort: 'createdAt,desc'
  };

  return useInfiniteQuery<PagedResponse<ControlNote>, WrappedError>({
    queryKey: [USE_FETCH_CUSTOMER_CONTROL_NOTES_QUERY_KEY, externalControlId, options],
    queryFn: async ({ pageParam }) =>
      await axiosInstance
        .get<PagedResponse<ControlNote>>(
          AZURE_CONTROLS_API_URLS.NOTES.replace(':controlId', `${externalControlId}`),
          { params: { ...options, page: pageParam } }
        )
        .then(response => response.data)
        .catch(catchApiError),
    enabled,
    gcTime: 1000,
    initialPageParam: 0,
    getNextPageParam: ({ page }) => (page.totalPages > page.number + 1 ? page.number + 1 : null)
  });
};

export const USE_FETCH_CUSTOMER_CONTROL_CHECKS_QUERY_KEY = 'useAzureFetchCustomerControlChecks';
export const useFetchCustomerControlChecks = (externalControlId: string) => {
  const { selectedCustomer } = useSelectedCustomer();

  return useQuery<CustomerControlCheck[], WrappedError>({
    queryKey: [USE_FETCH_CUSTOMER_CONTROL_CHECKS_QUERY_KEY, externalControlId],
    queryFn: async () =>
      await axiosInstance
        .get<CustomerControlCheck[]>(
          AZURE_CONTROLS_API_URLS.CONTROL_CHECKS.replace(':controlId', externalControlId),
          {
            params: { customerId: selectedCustomer?.id }
          }
        )
        .then(response => response.data)
        .catch(catchApiError)
  });
};

export const useControlPostureMutation = () => {
  const { selectedCustomer } = useSelectedCustomer();

  return useMutation<AzureCustomerControl, WrappedError, ControlPostureMutationOptions>({
    mutationKey: ['useAzureControlPostureMutation'],
    mutationFn: async ({ externalControlId, controlPosture }: ControlPostureMutationOptions) =>
      await axiosInstance
        .put<AzureCustomerControl>(
          AZURE_CONTROLS_API_URLS.CONTROL_POSTURE.replace(':controlId', externalControlId),
          {
            customerId: selectedCustomer?.id,
            controlPosture
          }
        )
        .then(response => response.data)
        .catch(catchApiError)
  });
};

export const useStatusMutation = () => {
  const { selectedCustomer } = useSelectedCustomer();

  return useMutation<AzureCustomerControl, WrappedError, StatusMutationOptions>({
    mutationKey: ['useAzureStatusMutation'],
    mutationFn: async ({ externalControlId, status }: StatusMutationOptions) =>
      await axiosInstance
        .put<AzureCustomerControl>(
          AZURE_CONTROLS_API_URLS.STATUS.replace(':controlId', externalControlId),
          {
            customerId: selectedCustomer?.id,
            status
          }
        )
        .then(response => response.data)
        .catch(catchApiError)
  });
};

export const useCustomerControlNotesMutation = () => {
  const { selectedCustomer } = useSelectedCustomer();
  const queryClient = useQueryClient();

  return useMutation<ControlNote, WrappedError, CustomerControlNotesMutationOptions>({
    mutationKey: ['useAzureCustomerControlNotesMutation'],
    mutationFn: async ({ externalControlId, note }: CustomerControlNotesMutationOptions) =>
      await axiosInstance
        .post<ControlNote>(AZURE_CONTROLS_API_URLS.NOTES.replace(':controlId', externalControlId), {
          customerId: selectedCustomer?.id,
          note
        })
        .then(response => response.data)
        .catch(catchApiError),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [USE_FETCH_CUSTOMER_CONTROL_NOTES_QUERY_KEY] })
  });
};
