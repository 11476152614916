import axios, { AxiosError } from 'axios';

import { ApiError, WrappedError } from 'app/types/ApiError';

export const getMessageFromError = (error?: WrappedError | null) => {
  if (error) {
    if ((error.content as Error).message) return (error.content as Error).message;
    if ((error.content as ApiError).errorMessage) return (error.content as ApiError).errorMessage;
  }
  return undefined;
};

// Catches Error or ApiError and will throw a WrappedError
export const catchApiError = (err: Error | AxiosError<ApiError>) => {
  const axiosError = axios.isAxiosError(err);
  throw {
    status: axiosError ? err.response?.status : undefined,
    content: axiosError ? err.response?.data : err
  } as WrappedError;
};
