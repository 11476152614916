import { createContext, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { PRODUCT_TYPES } from 'app/constants/app';
import { CONTROL_SORT_OPTIONS } from 'app/constants/controls';
import {
  USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY as AZURE_QUERY_KEY,
  useFetchCustomerControls as useFetchAzureCustomerControls
} from 'app/queries/useAzureControlQueries';
import {
  USE_FETCH_CUSTOMER_CONTROLS_QUERY_KEY as M365_QUERY_KEY,
  useFetchCustomerControls as useFetchM365CustomerControls
} from 'app/queries/useM365ControlQueries';
import { AzureCustomerControl, CommonFilters, M365CustomerControl, WrappedError } from 'app/types';
import {
  filterAzureControls,
  filterM365Controls,
  sortAzureControls,
  sortM365Controls
} from 'app/utils/control-utils';

import { useSelectedCustomer } from './useSelectedCustomer';

interface ControlsContextType {
  filteredData: Array<AzureCustomerControl | M365CustomerControl> | undefined;
  isFetching: boolean;
  error: WrappedError | null;
  filters: CommonFilters;
  setFilters: (filters: CommonFilters) => void;
  sort: string;
  setSort: (sort: string) => void;
  ready: boolean;
  setReady: (ready: boolean) => void;
  refetchControls: () => void;
}

const ControlsContext = createContext<ControlsContextType>({
  filteredData: [],
  isFetching: false,
  error: null,
  filters: {},
  setFilters: () => {},
  sort: CONTROL_SORT_OPTIONS[0].value,
  setSort: () => {},
  ready: false,
  setReady: () => {},
  refetchControls: () => {}
});

type ControlsProviderProps = { type: PRODUCT_TYPES; children: React.ReactNode };

const ControlsProvider: FunctionComponent<ControlsProviderProps> = ({ children, type }) => {
  const queryClient = useQueryClient();
  const { selectedCustomer } = useSelectedCustomer();

  // allows us to delay the query until we are ready (i.e. we have any filters from searchparams)
  const [ready, setReady] = useState(false);

  const [filters, setFilters] = useState<CommonFilters>({});
  const [sort, setSort] = useState<string>(CONTROL_SORT_OPTIONS[0].value);

  // manualTrigger allows us to trigger the filteredData useMemo below - we don't care what the actual value is
  const [manualTrigger] = useState(false);

  const productRelatedControlsQuery = () => {
    switch (type) {
      case PRODUCT_TYPES.AZURE:
        return useFetchAzureCustomerControls(ready);
      case PRODUCT_TYPES.M365:
        return useFetchM365CustomerControls(ready);
    }
  };

  const { data, isFetching, error, refetch } = productRelatedControlsQuery();

  const filteredData = useMemo(() => {
    if (isFetching) return data || [];
    if (type === PRODUCT_TYPES.M365)
      return sortM365Controls(
        filterM365Controls((data as M365CustomerControl[]) || [], filters),
        sort
      );
    if (type === PRODUCT_TYPES.AZURE)
      return sortAzureControls(
        filterAzureControls((data as AzureCustomerControl[]) || [], filters),
        sort
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, filters, isFetching, sort, manualTrigger]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: [M365_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [AZURE_QUERY_KEY] });
  }, [queryClient, selectedCustomer]);

  return (
    <ControlsContext.Provider
      value={{
        filteredData,
        isFetching,
        error,
        filters,
        setFilters,
        sort,
        setSort,
        ready,
        setReady,
        refetchControls: refetch
      }}
    >
      {children}
    </ControlsContext.Provider>
  );
};

const useControlsContext = () => useContext(ControlsContext);

export { ControlsProvider, useControlsContext };
