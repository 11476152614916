import { FunctionComponent } from 'react';
import { Box, Flex } from '@radix-ui/themes';
import { format } from 'date-fns';

import { SolutionIcon } from 'app/components/Controls';
import { M365CustomerControl, NISPrinciple } from 'app/types/m365Controls';

import { DetailsField } from './DetailsField';
import { DetailsSection } from './DetailsSection';
import { LicenseField } from './LicenseField';

type M365DetailsSectionProps = {
  control: M365CustomerControl;
  onControlChange: (control: M365CustomerControl) => void;
};

const formatNISValue = (nisPrinciples?: NISPrinciple[]) => {
  if (!nisPrinciples || !nisPrinciples.length) return;
  return (
    <>
      {nisPrinciples.map(({ id, name }) => (
        <span key={`nis-${id}`}>
          {name}
          <br />
        </span>
      ))}
    </>
  );
};

export const M365DetailsSection: FunctionComponent<M365DetailsSectionProps> = ({
  control,
  onControlChange
}) => {
  return (
    <DetailsSection label='Details'>
      <Box>
        <Flex gap='2' direction='column'>
          <DetailsField
            label='Last updated'
            value={control.lastUpdatedAt ? format(control.lastUpdatedAt, 'd MMM yyyy') : ''}
          />
          <LicenseField control={control} onControlChange={onControlChange} />
          <DetailsField label='Control version' value={control.version} />
          <DetailsField
            label='Solution'
            value={
              control.solution && (
                <Flex as='span' align='center' gap='8px'>
                  {control.solution?.name}
                  <SolutionIcon solution={control.solution} />
                </Flex>
              )
            }
          />
          <DetailsField label='NIS principles' value={formatNISValue(control.nisPrinciples)} />
        </Flex>
      </Box>
    </DetailsSection>
  );
};
